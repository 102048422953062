/* AddToolMenu */
@use '../../css/settings.scss' as pd;

.add_tool {
  position: fixed;
  right: 60px;
  top: 12px;
  z-index: 200;
}

.add_tool_icon {
  width: pd.size(normal, icons);
  aspect-ratio: 1 / 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: pd.size(normal, icons);
  background-image: url('../../../public/icons/plus-light.svg');
  border-radius: 50%;
  &:hover {
    background-color: pd.color(bg, blocks);
    background-image: url('../../../public/icons/plus-accent.svg');
  }
}

.add_tool.dropmenu {
  .dropmenu_items .dropmenu_items {
    padding: 0 0 0 pd.$lg;
  }
  .dropmenu_items .add_tool_tool {
    background-image: url('../../../public/icons/chat.svg');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: pd.size(medium, icons);
    margin-left: -10px;
    padding-left: calc(pd.size(medium, icons) + pd.$md);
    height: pd.size(medium, icons);
    display: flex;
    align-items: center;
    &.add_tool_Chat {
      background-image: url('../../../public/icons/chat.svg');
    }
    &.add_tool_Library {
      background-image: url('../../../public/icons/library.svg');
    }
    &.add_tool_List {
      background-image: url('../../../public/icons/list.svg');
    }
    &.add_tool_MultiList {
      background-image: url('../../../public/icons/list.svg');
    }
    &.add_tool_ProCon {
      background-image: url('../../../public/icons/procon.svg');
    }
    &.add_tool_SWOT {
      background-image: url('../../../public/icons/swot.svg');
    }
    &.add_tool_Kanban {
      background-image: url('../../../public/icons/kanban.svg');
    }
    &.add_tool_Document {
      background-image: url('../../../public/icons/document.svg');
    }
    &.add_tool_Poll {
      background-image: url('../../../public/icons/poll.svg');
    }
    &.add_tool_Meeting {
      background-image: url('../../../public/icons/meeting.svg');
    }
    &.add_tool_Sudoku {
      background-image: url('../../../public/icons/sudoku.svg');
    }
  }
}
