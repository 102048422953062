/* UserDataForm */
@use '../../css/settings.scss' as pd;

.user_status_form {
  .delete_user_section {
    display: flex;
    margin: pd.$xxl pd.$md pd.$md;
    justify-content: flex-end;
    div {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }
  }
  .user_id {
    display: flex;
    align-items: center;
  }
  .user_avatar {
    width: pd.size(avatar_small, icons);
    height: calc(pd.size(avatar_small, icons) + pd.$md);
    margin-right: pd.$xl;
  }
  .user_password,
  .user_regcode,
  .user_email,
  .user_name {
    @include pd.font($size: pd.size(large, font), $bold: true);
    margin-bottom: pd.$md;
  }
  .user_deleting {
    color: pd.color(signal, forms);
  }
}
