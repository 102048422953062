/* LibraryBlock */
@use '../../css/settings.scss' as pd;

.library_block.block_container {
  width: pd.size(library, blocks);
  max-width: pd.size(library_max, blocks);
  .block_content {
    padding: 0;
  }
}
.library {
  padding: 0 pd.$md 0;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  .files_container {
    // height: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .files_header {
    padding: pd.$md;
  }
  .files {
    display: flex;
    flex-direction: column;
    // height: unset;
    flex: 1 1 auto;
    overflow: auto;
  }
  .files_description,
  .files_description > div {
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // cursor: pointer;
    &.editing {
      overflow: unset;
    }
  }
  .files_link {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .files_size {
    flex: 0 0 auto;
  }
  .files_icon {
    flex: 0 0 auto;
    margin-right: pd.$md;
    width: 24px;
  }
  .files_date,
  .msg_menu {
    margin-left: pd.$md;
    flex: 0 0 auto;
    .date {
      @include pd.font($weight: regular);
    }
  }
  .files_sortby:not(.files_disabled) {
    cursor: pointer;
  }
  .files_header,
  .folder .collapsable_controller,
  .file {
    display: flex;
    width: 100%;
    padding: pd.$sm pd.$md;
    @include pd.font($size: pd.size(msg, font));
    border-bottom: 1px solid pd.color(silverhair);
  }
  .files_header {
    @include pd.font($bold: true);
  }
  .file {
    .dropmenu_trigger {
      // flex: 0 0 auto;
      position: relative;
      width: unset;
      &:hover {
        background-image: url('../../../public/icons/dropmenu.svg');
        .files_date {
          opacity: 0;
        }
      }
    }
    .form-control {
      position: relative;
      z-index: 10;
      padding: pd.$tiny pd.$md;
      margin: pd.$mtiny pd.$mmd;
      @include pd.font($size: pd.size(msg, font));
      width: 100%;
      border: 0;
    }
  }
  .folder.collapsable {
    > .collapsable_controller {
      width: 100%;
    }
    .file {
      padding-left: pd.$xxl;
    }
    &.height > .collapsable_content {
      margin-bottom: unset;
    }
  }
  .tail_container {
    background-color: unset;
    flex: 0 0 auto;
    padding: pd.$md 0;
  }
  .msg_tail .msg_input_field.form-control {
    border: 2px solid pd.color(tailbg, blocks);
  }
}
.no_files {
  padding: pd.$md;
  @include pd.font($italic: true);
}
