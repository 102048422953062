/* DropMenu */
@use '../../css/settings.scss' as pd;

.dropmenu {
  position: relative;
  &.dropmenu_active .dropmenu_trigger + .toolhint {
    opacity: 0;
  }
  &.dropmenu_noposition {
    position: unset;
  }
}
.dropmenu_trigger {
  z-index: 10;
  cursor: pointer;
  &.ellipsis {
    background-image: url('../../../public/icons/dropmenu.svg');
    &:hover {
      background-image: url('../../../public/icons/dropmenu-dark.svg');
    }
    background-repeat: no-repeat;
    background-position: center;
    background-size: pd.size(tiny, icons);
    height: pd.size(small, icons);
    aspect-ratio: 1 / 1;
    + .dropmenu_items {
      top: 24px;
      right: 0;
    }
  }
}
.dropmenu_items {
  background: pd.color(bg, cards);
  color: pd.color(text, cards);
  font-size: pd.$font_size;
  border-radius: pd.$xl;
  position: absolute;
  top: 28px;
  right: 14px;
  box-shadow: pd.$box_shadow;
  white-space: nowrap;
  padding: pd.$lg;
  // padding: 0;
  // height: 0; // important, so the menu does not occupy space when hidden
  // overflow: visible; // if set to auto or scroll, this shows up as a bug in Safari when visibility: hidden
  display: none;
  z-index: 999;
  > *:not(.dropmenu_note) {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  > *:not(.dropmenu_note, .dropmenu_items) {
    width: 100%;
  }
  &.disabled,
  .disabled {
    pointer-events: none;
    color: pd.color(lighttext, blocks);
  }
  .dropmenu_divider {
    border-top: 1px solid pd.color(border, forms);
    margin: pd.$sm 0;
  }
  .dropmenu_note {
    // margin-left: pd.$sm;
    &:not(:first-child) {
      margin-top: pd.$sm;
    }
    @include pd.font($bold: true);
  }
  .form-check {
    margin-left: 0 !important;
    label {
      width: 100%;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .pd_icon {
    position: relative;
    float: right;
    + * {
      margin-right: pd.size(normal, icons);
    }
  }
}
.dropmenu_items .dropmenu_items {
  position: relative;
  top: unset;
  right: unset;
  box-shadow: unset;
  border-radius: 0;
  padding: 0;
  &:hover {
    text-decoration: unset;
  }
  > * {
    margin-left: 0;
  }
}
.dropmenu_right .dropmenu_items {
  right: unset;
}
.dropmenu_up .dropmenu_items {
  top: unset;
  bottom: 40px;
}
.dropmenu {
  .dropmenu_items {
    visibility: hidden;
  }
  &.dropmenu_active .dropmenu_content,
  &.dropmenu_active .dropmenu_items {
    visibility: visible;
    // height: auto;
    // padding: pd.$lg;
    display: block;
  }
}
