/* Test */
@use '../css/settings.scss' as pd;

.t_dropzone {
  background-color: beige;
  color: black;
  width: 300px;
  height: 200px;
}

.layout {
  .test.static_page {
    margin: 20px 10px;
  }
  pre {
    overflow: unset;
  }
  .t_c {
    display: flex;
    width: 100%;
  }
  .t_1 {
    flex: 0 1 auto;
    overflow: auto;
    white-space: nowrap;
    width: 200px;
    border: 1px solid white;
    visibility: hidden;
  }
}

.test {
  .block {
    width: 300px;
    height: 300px;
    background-color: green;
  }
  .sortable_container {
    display: flex;
    flex-direction: row;
  }
  .sortable.dragging {
    opacity: 0.5;
    .popmenu {
      visibility: hidden;
    }
  }
  .sortable_list {
    width: 50%;
    align-self: stretch;
    height: unset;
    background-color: gray;
    &.draggedOver {
      background-color: azure;
    }
    .msg {
      margin-top: 0;
    }
    .sortable:nth-of-type(3) {
      margin-left: auto;
    }
  }
}
