/* AbsoluteContent */
@use '../../css/settings.scss' as pd;

.absolute_content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  z-index: 2000;
  margin-top: 92px;
  margin-left: auto;
  padding-bottom: pd.$xl;
  border-top-left-radius: pd.$md;
  color: black;
  order: 2;
  overflow: hidden;
}
.absolute_menu {
  border-top-left-radius: pd.$md;
  padding: pd.$sm 0 0 pd.$sm;
  position: absolute;
}
