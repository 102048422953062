/* Icon */
@use '../../css/settings.scss' as pd;

.pd_icon {
  // color: transparent;
  height: pd.size(small, icons);
  aspect-ratio: 1;
  position: relative;
  .pd_icon_img {
    position: absolute;
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-size: contain;
    aspect-ratio: 1;
    height: inherit;
  }
  > * {
    z-index: 30;
  }
  &.inline {
    display: inline-block;
  }
}

.pd_alert .pd_icon.smallmargin,
.pd_icon.smallmargin {
  margin: 0 pd.$md;
}

.pd_icon_blur {
  &:before {
    background-color: #fff; // as form-control
    filter: blur(3px);
    content: ' ';
    height: 110%;
    aspect-ratio: 1 / 1;
    display: block;
    position: absolute;
    z-index: 10;
  }
}
