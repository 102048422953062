/* FileUpload */
@use '../../css/settings.scss' as pd;

.dropzone {
  border: 2px dashed transparent;
  width: 100%;
  height: 100%;
  .dropzone_container {
    position: relative;
  }
  &.targeted {
    // border: 2px dashed pd.color(dropzone, forms);
    background-color: pd.color(dropzone, upload, true);
    * {
      pointer-events: none;
    }
  }
  .draggedOver {
    background-color: red;
  }
  .dropzone_bin,
  .dropzone_trigger {
    bottom: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
    float: left;
  }
  .dropzone_trigger {
    @include pd.bgicon('pencil', '', right, $size: 24px);
    float: right;
  }
  .dropzone_bin {
    @include pd.bgicon('bin', '', left, $size: 24px);
    left: 0;
  }
  .alert {
    padding: pd.$lg;
    @include pd.font($size: pd.size(normal, font));
  }
}
.preview_image {
  position: relative;
  margin: auto;
  max-height: 180px;
  // aspect-ratio: 1/1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
