.scroll_icon {
  position: relative;
  height: 0;
  z-index: 1001;
  cursor: pointer;
  &::after {
    display: block;
    position: absolute;
    content: ' ';
    background-image: url('../../../public/icons/scroll.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
    width: 32px;
    height: 32px;
    right: 0;
    bottom: 0;
  }
  &.hidden {
    display: none;
  }
  &.more::after {
    background-image: url('../../../public/icons/scroll-accent.svg');
  }
}

// TODO: need to review this on Windows
.custom_scrollbar + div {
  scrollbar-width: 7px;
  scrollbar-color: rgba(0, 0, 0, 0.5);
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    &:hover {
      width: 11px;
    }
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    &:hover {
      border-radius: 6px;
    }
  }
}
