/* ProjectsForm */
@use '../../css/settings.scss' as pd;

.pd_modal {
  .projects_form .pd_form:not(.editing) *,
  .projects_form .pd_form * {
    transition: none;
  }
  .projects_form {
    height: 90%;
    .pd_form {
      .modal-body,
      .modal_inner_wrapper {
        height: 100%;
        overflow-y: hidden;
        display: flex;
        flex-direction: column;
      }
      .modal-body {
        padding: 0;
      }
      .modal_inner_wrapper {
        padding: 0 2 * pd.$lg;
      }
      .pagination_styles .pagination {
        margin: unset;
      }
      .page_arrow .page-link span {
        @include pd.font($size: 1.5rem);
        line-height: 0;
      }
      .table_projects {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
      }
      .projects_thead {
        position: sticky;
        top: 0;
        flex: 0;
        display: flex;
        flex-direction: row;
        @include pd.font($bold: true);
        border-bottom: 1px solid pd.color(border, forms);
        div {
          border-top: 0;
        }
      }
      .projects_tbody {
        overflow-y: auto;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      }
      .project_row {
        display: flex;
        flex-direction: row;
        width: 100%;
        &:not(:first-of-type) {
          border-top: 1px solid pd.color(border, forms);
        }
        > div {
          padding: pd.$sm;
        }
        &:hover {
          .user_moderator {
            visibility: visible;
          }
        }
      }
      .project_name {
        flex: 1 1 auto;
      }
      .dialogue_status,
      .project_status {
        display: flex;
        position: relative;
        @include pd.font($size: pd.size(smaller, font));
        select,
        input,
        .regcode {
          cursor: pointer;
        }
        .regcode {
          width: 50%;
          flex-grow: 0;
          padding: 0 4px;
          @include pd.font($size: pd.size(smaller, font));
          margin-right: 8px;
        }
        .status {
          width: 50%;
          flex-grow: 1;
          padding: 0 4px;
          @include pd.font($size: pd.size(smaller, font));
        }
      }
      .project_participants {
        flex: 0 0 15%;
        .user_list {
          width: 100%;
        }
      }
      .project_dialogues {
        flex: 0 0 50%;
        display: flex;
        flex-direction: column;
        .dialogue_item {
          width: 100%;
          display: flex;
          flex-direction: row;
          &:not(:first-child) {
            border-top: 1px solid pd.color(border_light, forms);
          }
          .dialogue_name {
            flex: 0 0 60%;
          }
          .dialogue_participants {
            flex: 0 0 40%;
            .user_list {
              width: 100%;
            }
          }
        }
      }
      .project_save {
        width: 90px;
        flex: 0 0 90px;
        display: flex;
        flex-direction: column;
        button {
          display: block;
          height: 24px;
          min-width: 80px;
          padding: 0 pd.$sm;
          &:not([disabled]) {
            opacity: 1;
          }
          opacity: 0;
        }
      }
      .collapsable {
        padding: 0 !important;
        .collapsable_content {
          margin-bottom: unset;
        }
      }
      .item_remove {
        width: pd.size(tiny, icons);
        visibility: hidden;
        margin: 0 0 0 pd.$xl;
      }
      .user_moderator {
        width: pd.size(tiny, icons);
        margin: 0 0 0 auto;
        &:not(.is_moderator) {
          visibility: hidden;
        }
      }
      .add_menu {
        margin-top: pd.$md;
      }
    }
  }
}
