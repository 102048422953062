/* List */
@use '../../css/settings.scss' as pd;

.list_head {
  display: flex;
  align-items: center;
  padding: 0 pd.$xl 0 calc(pd.$xl + pd.$md);
}
.list_name {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  @include pd.font($bold: true);
  div:not(.pd_icon_img) {
    margin: 0 pd.$sm;
  }
}
.list_icon {
  flex: 0 0 auto;
}
.list_content {
  display: flex;
  padding: 0 pd.$md;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
  overflow-anchor: none;
}
.sortable_preview,
.list_content {
  .sortable_wrapper {
    padding: 0 pd.$md 0 0;
  }
  .list_item {
    width: 100%;
    flex: 1 1 auto;
    margin: 0 0 0 pd.$xl;
    &.byme .sortable_handle {
      background-color: pd.color(mybg, chat);
    }
    &:hover:not(.pinned):not(.dragging) {
      .msg:not(.msg_mini) {
        border-top-left-radius: 0;
      }
    }
  }
  .item_pinned {
    position: absolute;
    right: 0px;
    top: -8px;
  }
  .attribution.msg {
    padding-bottom: pd.$md;
  }
  .msg:not(.attribution) .msg_edited,
  .msg:not(.attribution) .msg_author {
    display: none;
  }
  .msg {
    margin: unset;
    width: 100%;
    .msg_header {
      height: unset;
    }
    .msg_time {
      display: none;
    }
    .msg_menu {
      position: absolute;
      right: pd.size(lg);
      top: pd.size(tiny);
      > * {
        z-index: 10;
      }
    }
    .msg_replies {
      .collapsable_controller {
        width: auto;
        height: 16px;
        z-index: 5;
        background-color: unset;
        &:hover {
          background-color: unset;
        }
        .collapsable_icon {
          margin-left: auto;
          margin-right: 0;
        }
      }
      .collapsable_content {
        margin-bottom: 0;
        flex-direction: column;
        &.show {
          display: flex;
        }
      }
      .msg_tail,
      .msg {
        margin-top: pd.$tiny;
        padding: pd.$tiny 0 0 pd.$md;
        .msg_author {
          margin-right: pd.$md;
          &.same {
            display: none;
          }
        }
      }
      .msg_input_send {
        height: 24px;
        margin-left: pd.$sm;
      }
      .msg_input {
        margin-right: 0;
      }
    }
  }
}

.block:not(.locked) .sortable:hover {
  .item_pinned {
    visibility: hidden;
  }
}

.list_container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  .list_content.pinned {
    flex: 1 0 auto;
    height: auto;
  }
}
