/* forms */
@use './settings.scss' as pd;

#pd_modals {
  .pd_modal_backdrop {
    z-index: 1100;
    ~ .pd_modal_backdrop {
      z-index: 1200;
      ~ .pd_modal_backdrop {
        z-index: 1300;
      }
    }
  }
  .pd_modal {
    z-index: 1150;
    ~ .pd_modal {
      z-index: 1250;
      ~ .pd_modal {
        z-index: 1350;
      }
    }
  }
}
.pd_modal {
  color: pd.color(text, forms);
  .modal-dialog {
    display: flex;
    flex-direction: column;
    max-height: 90%;
    justify-content: center;
    width: auto;
    max-width: unset;
    width: 700px;
    &.narrow {
      width: 400px;
    }
    &.medium {
      width: 500px;
    }
    &.wide {
      width: min(1100px, max(900px, 80vw));
      transition: width 300ms ease-in-out 100ms;
    }
    &.max_width {
      // width: min(1260px, max(900px, 90vw));
      width: 95%;
    }
    .modal-content {
      margin: 0;
      border-radius: pd.$md;
      height: 100%;
      min-height: 200px;
      overflow-y: auto;
      overflow-y: hidden;
    }
    &.max_height .modal-content {
      flex: 1 0 10px;
    }
    .pd_form {
      display: flex;
      flex-direction: column;
      height: 100%;
      flex: 1 1 100%;
      overflow: auto;
    }
    .modal-header {
      align-items: flex-start;
      flex: 0;
      .btn-close {
        border-radius: 100%;
        aspect-ratio: 1;
        // margin-right: pd.$sm;
        // margin-top: pd.$sm;
      }
    }
    .modal-header:not(.with_title) {
      position: absolute;
      border-bottom: none;
      right: 0;
      z-index: 10;
    }
    .modal-header.with_title {
      padding-left: 2 * pd.$lg;
    }
    .modal-body {
      position: relative;
      flex: 1 1 0;
      overflow-y: auto;
      margin: pd.$lg 0 0;
      padding: 0 2 * pd.$lg;
    }
    .modal_inner_wrapper {
      position: relative;
      height: 100%;
    }
    .modal-footer {
      flex: 0;
      justify-content: center;
      .alert {
        flex-basis: 100%;
      }
    }
    &.bare {
      .modal-header {
        padding: 0;
        height: 0;
        border: 0;
        z-index: 10;
        button {
          background-color: white;
          opacity: 0.8;
          margin: 10px 10px auto auto;
        }
      }
      .modal-body {
        margin: 0;
        padding: 0;
      }
      .modal-title {
        display: none;
      }
      .pd_form,
      .modal-content {
        // background-color: transparent;
        background-color: pd.color(bg50, forms);
      }
    }
    .form-text {
      color: pd.color(text, forms);
      @include pd.font($size: normal);
    }
  }
}

// .modal-dialog {
.title {
  @include pd.font($condensed: true, $bold: true, $size: pd.size(h2, font));
}
.text {
  @include pd.font($size: pd.size(normal, font));
  // margin: 0 0 pd.$md;
}
.warning {
  @include pd.font($size: pd.size(normal, font), $bold: true);
  color: pd.color(warning);
  // margin: pd.$md 0;
}
.btn.wide {
  width: 100%;
}
.right {
  margin-left: auto;
  float: right;
}
// }

#pd_modals_wrapper {
  position: fixed;
  z-index: 1500; // be on top of 'normal' modals
}

.pd_alert {
  width: 300px;
  text-align: center;
  .pd_icon {
    margin: auto;
  }
  &.serious .modal-content {
    background-color: red;
    color: white;
  }
}

.pd_form {
  display: flex;
  flex-direction: column;
  color: pd.color(text, forms);
  background: pd.color(bg, forms);
}
// .sortable_preview,
.pd_form {
  &:not(.editing) * {
    transition: all 300ms ease-in-out 100ms;
  }
  .header {
    color: pd.color(accent, forms);
    padding: 0 0.375rem;
  }
  // .tadiv is used to calculate the height of textareas, and should not transition
  .tadiv {
    transition: none;
  }
  .nav_item {
    display: block;
    margin-left: unset;
    margin-bottom: pd.$lg;
  }
  .private {
    @include pd.bgicon('hidden', '', right, $size: 32px);
  }
  .form-check-label,
  .form-label {
    color: pd.color(label, forms);
  }
  .form-label::after {
    content: ':';
  }
  .form-check {
    margin-left: pd.$xl;
    &.novalidation {
      label {
        color: pd.color(label, forms) !important;
      }
      input {
        margin-left: pd.$mxl;
        margin-right: pd.$sm;
        border-color: inherit !important;
        &:checked {
          border-color: #0d6efd !important;
          background-color: #0d6efd !important;
        }
      }
    }
  }
  .image_preview {
    position: relative;
    width: 120px;
    margin-top: 2px;
  }
  .alert {
    margin-bottom: pd.$md;
    padding: pd.$lg;
  }
  .cancel {
    visibility: hidden;
    opacity: 0;
  }
  &.editing .cancel {
    visibility: visible;
    opacity: 1;
  }
}
.row {
  overflow: visible;
  position: relative;
  margin-bottom: pd.$sm;
  &.last_row {
    margin-bottom: pd.$lg;
  }
}
.grid {
  display: grid;
}
.form-control,
.as-form-control,
.instruction {
  padding: 0.15rem 0.75rem;
  display: block;
}
.vspaced {
  margin-top: pd.$lg;
}
.period {
  flex: 0 0 auto;
}
.date,
.time {
  text-align: right;
  white-space: nowrap;
  width: unset;
}
.date,
.date input {
  @include pd.font($bold: true);
}
.timespan,
.time,
.time input {
  @include pd.font(
    $weight: 'regular',
    $italic: true,
    $size: pd.size(normal, font)
  );
}
.date input,
.time input {
  width: 120px;
}
.subheading {
  @include pd.font($size: pd.size(large, font));
  .date input,
  .time input {
    width: 135px;
  }
}
.datespan,
.timespan {
  display: flex;
  justify-content: flex-end;
  margin-left: pd.$sm;
}
.datespan .date_sep {
  // margin-top: pd.$sm;
  margin-top: 0;
}
.from,
.to {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
textarea.form-control {
  resize: none;
  min-height: unset;
}
.as-form-control {
  padding: 0.375rem 0.75rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control-padding {
  padding: 0.375rem 0.75rem;
}
.pd_form.not_editing .form-control,
.pd_form.not_editing .as-form-control,
.form-control:disabled,
.form-select:disabled,
.form-control.disabled {
  background-color: white;
  color: pd.color(text, forms);
  // -webkit-text-fill-color: pd.color(text, forms); // for Safari
  opacity: 1; /* required on iOS */
  border-color: transparent;
  appearance: none;
  background-image: unset; // this hides the dropdown arrow in select
}
