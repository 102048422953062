/* EmojisButton */
@use '../../css/settings.scss' as pd;

.emoji_picker_button {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 20;
}
.icon_emojis {
  position: absolute;
  right: 8px;
  bottom: 8px;
  z-index: 100;
}
.msg_content {
  .icon_emojis {
    right: 4px;
    top: unset;
    bottom: 6px;
  }
  .emoji_picker {
    right: -32px; // keeps the picker for enough to the right for left-sided msgs
  }
}

.emoji_picker {
  position: absolute;
  bottom: 32px;
  right: 0px;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.3);
  z-index: 600;
  .EmojiPickerReact.epr-main {
    --epr-emoji-size: 24px;
    --epr-header-padding: 6px var(--epr-horizontal-padding) 3px;
    --epr-category-navigation-button-size: 18px;
    --epr-category-label-height: 24px;
    h2 {
      font-size: 14px;
    }
  }
  &.emoji_picker_bottom {
    bottom: unset;
    top: 0px;
  }
  &.emoji_picker_closed {
    display: none;
  }
}
