/* PopMenu */
@use '../../css/settings.scss' as pd;

.popmenu {
  position: relative;
  visibility: hidden;
  &.popmenu_active .popmenu_items + .toolhint {
    opacity: 0;
  }
  &.popmenu_noposition {
    position: unset;
  }
}
*:hover > .popmenu {
  visibility: visible;
  .popmenu_items {
    height: auto;
  }
}
.popmenu_items {
  background: pd.color(bg, popmenu);
  color: pd.color(text, cards);
  font-size: pd.$font_size;
  border-radius: pd.$lg;
  border: 1px solid pd.color(border, popmenu);
  position: absolute;
  top: -12px;
  right: -8px;
  box-shadow: pd.$box_shadow;
  white-space: nowrap;
  padding: pd.$sm pd.$md;
  height: 0; // important, so the menu does not occupy space when hidden
  // overflow: visible; // if set to auto or scroll, this shows up as a bug in Safari when visibility: hidden
  z-index: 999;
  cursor: pointer;
  display: flex;
  gap: pd.$sm;
  // > *:not(.popmenu_note) {
  //   cursor: pointer;
  //   &:hover {
  //     text-decoration: underline;
  //   }
  // }
  // > *:not(.popmenu_note, .popmenu_items) {
  //   width: 100%;
  // }
  // &.disabled {
  //   pointer-events: none;
  //   color: pd.color(lighttext, blocks);
  // }
  // .popmenu_divider {
  //   border-top: 1px solid pd.color(border, forms);
  //   margin: pd.$sm 0;
  // }
  // .popmenu_note {
  //   margin-left: pd.$lg;
  //   margin-top: pd.$sm;
  //   @include pd.font($bold: true);
  // }
  // .form-check {
  //   margin-left: 0 !important;
  // }
  // .pd_icon {
  //   position: relative;
  //   float: right;
  //   + * {
  //     margin-right: pd.size(normal, icons);
  //   }
  // }
}
// .popmenu_items .popmenu_items {
//   position: relative;
//   top: unset;
//   right: unset;
//   box-shadow: unset;
//   border-radius: 0;
//   padding: 0;
//   &:hover {
//     text-decoration: unset;
//   }
//   > * {
//     margin-left: 0;
//   }
// }
// .popmenu_right .popmenu_items {
//   right: unset;
// }
// .popmenu_up .popmenu_items {
//   top: unset;
//   bottom: 40px;
// }
// .popmenu {
//   .popmenu_items {
//     // visibility: hidden;
//   }
//   &.popmenu_active .popmenu_content,
//   &.popmenu_active .popmenu_items {
//     // visibility: visible;
//     // height: auto;
//   }
// }
