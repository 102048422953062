/* Sudoku */
@use '../../css/settings.scss' as pd;
@import url('https://use.typekit.net/egz8oce.css');

.sudoku {
  margin-top: pd.$lg;
  border: 0px solid gold;
  transition: border-width 0.1s ease-in;
  &.sudoku_solved {
    border-width: 5px;
  }
  &:not(.sudoku_solved) {
    border-width: 0px;
  }
}
.sudoku_grid {
  display: flex;
  flex-direction: column;
  > div:nth-child(1) .sudoku_square {
    border-top: 1px solid grey;
  }
  > div:nth-child(3) .sudoku_square,
  > div:nth-child(6) .sudoku_square {
    border-bottom: 2px solid black;
  }
  .sudoku_row {
    display: flex;
    flex-direction: row;
    > div:nth-child(1) {
      border-left: 1px solid grey;
    }
    > div:nth-child(3),
    > div:nth-child(6) {
      border-right: 2px solid black;
    }
  }
}
.sudoku_square {
  flex: 1;
  aspect-ratio: 1 / 1;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  div {
    flex: 0 0 33.33%;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    @include pd.font($size: pd.size(smaller, font));
    color: pd.color(hints, sudoku);
    overflow: hidden;
  }
  &.sudoku_challenge div,
  &.sudoku_attempt div {
    flex: 1 1 100%;
    @include pd.font($size: pd.size(larger, font));
    background-color: whitesmoke;
    color: pd.color(challenge, sudoku);
  }
  &.sudoku_attempt div {
    // font-family: 'felt-tip-woman', sans-serif;
    font-family: 'felt-tip-woman', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2rem;
    padding-top: 4px;
    background-color: unset;
    color: blue;
    height: 100%;
  }
  &.sudoku_error {
    div {
      background-color: red;
      color: white;
    }
  }
  &.sudoku_attempt div:hover {
    background-color: orange;
    color: white;
    cursor: pointer;
  }
  div.sudoku_option {
    visibility: hidden;
    aspect-ratio: 1 / 1;
  }
  &.sudoku_hint div.sudoku_option,
  &:hover div.sudoku_option {
    visibility: visible;
  }
  &:hover div.sudoku_option:hover {
    // background-color: greenyellow;
    // color: black;
    background-color: pd.color(accent);
    color: white;
    cursor: pointer;
    @include pd.font($size: pd.size(normal, font));
  }
  .sudoku_unique_option {
    color: red;
  }
  &.sudoku_empty {
    background-color: darksalmon;
  }
}
.block.locked div.sudoku_option {
  visibility: hidden;
}
.sudoku_difficulty {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  @include pd.font($bold: true);
  .sudoku_difficulty_level,
  .sudoku_difficulty_level label {
    cursor: pointer;
  }
}
.sudoku_level {
  display: flex;
  justify-content: flex-start;
  margin-top: pd.$lg;
}
.sudoku_solutions {
  margin-top: pd.$lg;
}
.sudoku_data {
  font-family: monospace;
  font-size: 8px;
}
.sudoku_buttons {
  display: flex;
  gap: 20px;
  div {
    cursor: pointer;
    padding: 0 4px;
    border: 1px solid grey;
    border-radius: 4px;
    color: grey;
    &:hover {
      background-color: black;
      color: white;
    }
  }
}
.sudoku_hintlevel {
  display: flex;
  &:hover {
    text-decoration: none !important;
  }
  div {
    text-align: center;
    padding: 0 4px;
    aspect-ratio: 1 / 1;
    flex: 1 1 auto;
    border: 1px solid grey;
    border-radius: 4px;
    &.sudoku_hintlevel_selected,
    &:hover {
      background-color: pd.color(accent);
      color: pd.color(white);
    }
  }
}
