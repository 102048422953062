/* PollSettingsForm */
@use '../../css/settings.scss' as pd;

.poll_settings_form .modal-content {
  min-height: 550px;
}

.pd_form {
  .poll_period {
    display: flex;
    .datespan {
      margin-right: pd.$sm;
      .date_sep {
        margin-top: unset;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .date,
      .time {
        width: 130px;
      }
    }
    .poll_period_column {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      .poll_period_buttons {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        width: min-content;
        white-space: nowrap;
      }
      &:first-child {
        .poll_period_buttons {
          margin-left: auto;
        }
      }
    }
    button {
      // height: 28px;
      padding: 0.15rem 0.375rem;
      width: auto;
      color: pd.color(text, forms);
      background-color: pd.color(itembg, forms);
    }
  }
  .poll_period,
  .poll_checkbox,
  .poll_instr {
    margin-left: pd.$md;
  }
}
