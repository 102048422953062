/* UserForm */
@use '../../css/settings.scss' as pd;

.user_form {
  .pd_form {
    .preview_image {
      width: 120px;
      max-width: 120px;
      max-height: 120px;
      // aspect-ratio: 1 / 1;
      border-radius: 60px;
      border: 1px solid pd.color(border, forms);
      background-clip: padding-box;
    }
    .default_user {
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }
    .language {
      padding: 0.15rem 0.75rem;
      .form-select {
        width: auto;
        display: inline;
      }
    }
    .dropzone {
      box-sizing: content-box;
      height: unset;
      position: relative;
    }
    .user_nocode {
      padding-bottom: pd.$md;
      margin-bottom: pd.$md;
      border-bottom: 1px solid pd.color(border, forms);
    }
  }
  &.user_signup {
    .subrow {
      display: flex;
      flex: 1 1 auto;
      margin: 0;
      padding: 0;
      input {
        flex: 1 1 auto;
        // width: unset;
      }
      button {
        flex: 0 0 auto;
      }
    }
    .consent {
      display: flex;
      > * {
        flex: 1 1 auto;
        width: unset;
        display: flex;
        flex-wrap: nowrap;
      }
    }
    .consent_form {
      display: flex;
      flex-direction: column;
      position: relative;
      h1,
      hr {
        color: pd.color(accent);
      }
      hr {
        opacity: 1;
        border: 0;
        border-top: 1px solid pd.color(accent);
      }
      .read_more {
        position: absolute;
        right: 0;
        top: 0;
      }
      .consent_more {
        @include pd.font($bold: true);
        margin: pd.$md 0 pd.$md auto;
      }
      .consent_img {
        img {
          display: block;
          margin: auto;
          float: right;
          max-height: 240px;
        }
      }
      .indent {
        padding-left: 32px;
      }
    }
    .row.subrow {
      > * {
        width: auto;
        max-width: unset;
      }
      .form-check {
        padding: 0 0 0 pd.$lg;
      }
    }
  }
}
