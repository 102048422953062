/* PollBlock */
@use '../../css/settings.scss' as pd;

.block {
  .poll {
    .poll_timing {
      padding: 0 pd.$md;
      div {
        display: inline;
      }
      .date {
        @include pd.font($bold: true);
      }
      &.poll_timetostart,
      &.poll_timetoend,
      &.poll_closed {
        @include pd.font($bold: true);
      }
    }
    .poll_voting .collapsable_controller {
      display: flex;
      width: 100%;
      padding: 0 pd.$md;
      background-color: unset;
      @include pd.font($bold: true, $size: 120%);
      color: pd.color(accent);
      &:hover {
        background-color: unset;
      }
      .collapsable_icon {
        order: 2;
        margin-left: auto;
      }
    }
    .poll_options {
      display: flex;
      flex-direction: column;
      padding: pd.$md;
      width: 100%;
      height: unset;
      .poll_option {
        background-color: pd.color(option_bg, poll);
        display: flex;
        position: relative;
        width: 100%;
      }
    }
    .msg {
      width: 100%;
      margin: unset;
      height: unset;
      padding: pd.$xl pd.$lg pd.$xl pd.$xxl;
      background-color: unset;
      .msg_header {
        height: unset;
      }
      .msg_author {
        display: none;
      }
      .msg_menu {
        position: absolute;
        right: var(--pd-lg);
        top: var(--pd-tiny);
      }
      .msg_wrapper {
        display: flex;
      }
      .msg_content {
        order: 2;
      }
      .extraContent.poll_vote {
        order: 1;
      }
      .extraContent.poll_result {
        order: 3;
      }
      .poll_vote {
        margin-right: pd.$lg;
      }
    }
    .poll_vote_buttons {
      padding: 0 pd.$md;
    }
    .poll_results {
      margin: pd.$md;
      padding: 0 pd.$lg;
      border: 1px solid pd.color(border, forms);
      border-top-width: pd.$md;
      .poll_option {
        padding: pd.$xl 0 0;
        position: relative;
        &:not(:last-child) {
          border-bottom: 8px solid pd.color(gauge_bg, poll);
        }
        .poll_gauge {
          position: absolute;
          bottom: -8px;
          height: pd.$md;
          background-color: pd.color(gauge, poll);
        }
      }
      .poll_notvoted {
        padding: pd.$lg 0;
      }
      .msg {
        background-color: unset;
        border: unset;
        // padding: pd.$sm 0;
        padding: 0;
      }
      .poll_result {
        white-space: nowrap;
      }
    }
  }
}
