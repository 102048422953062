/* UserAvatar */
@use '../../css/settings.scss' as pd;

.user_avatar {
  position: relative;
  width: pd.size(avatar, icons);
  height: pd.size(avatar, icons);
  background-image: url('../../../public/icons/user-light.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  &.user_avatar_dark {
    background-image: url('../../../public/icons/user-dark.svg');
  }
  &.user_avatar_large {
    width: pd.size(avatar_large, icons);
    height: pd.size(avatar_large, icons);
  }
  &.user_avatar_medium {
    width: pd.size(avatar_medium, icons);
    height: pd.size(avatar_medium, icons);
  }
  &.user_avatar_small {
    width: pd.size(avatar_small, icons);
    height: pd.size(avatar_small, icons);
  }
  &.user_avatar_tiny {
    width: pd.size(avatar_tiny, icons);
    height: pd.size(avatar_tiny, icons);
  }
  .user_letter {
    width: 100%;
    height: 100%;
  }
}

.layout.white {
  .user_avatar {
    background-image: url('../../../public/icons/user.svg');
  }
}
