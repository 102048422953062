/* UsersForm */
@use '../../css/settings.scss' as pd;

.pd_modal {
  .users_form .pd_form:not(.editing) *,
  .users_form .pd_form * {
    transition: none;
  }
  .users_form {
    height: 90%;
    .pd_form {
      .modal-body,
      .modal_inner_wrapper {
        height: 100%;
        overflow-y: hidden;
        display: flex;
        flex-direction: column;
      }
      .modal-body {
        padding: 0;
      }
      .modal_inner_wrapper {
        padding: 0 2 * pd.$lg;
      }
      .pagination_styles .pagination {
        margin: unset;
      }
      .page_arrow .page-link span {
        @include pd.font($size: 1.5rem);
        line-height: 0;
      }
      .table_users {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
      }
      .users_thead {
        position: sticky;
        top: 0;
        flex: 0;
        display: flex;
        flex-direction: row;
        @include pd.font($bold: true);
        border-bottom: 1px solid pd.color(border, forms);
        div {
          border-top: 0 !important;
        }
      }
      .users_tbody {
        overflow-y: auto;
        overflow-x: hidden;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      }
      .user_row {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        &:hover {
          .user_moderator,
          .item_remove {
            visibility: visible;
          }
          .user_name,
          .user_role {
            text-decoration: underline;
          }
          .user_save button[disabled] {
            opacity: 0;
          }
        }
        &:not(:first-of-type) {
          border-top: 1px solid pd.color(border, forms);
        }
      }
      .user_project_row {
        display: flex;
        flex-direction: row;
        width: 100%;
        overflow-y: visible;
        > div {
          padding: 5px 15px 5px 5px;
          > * {
            padding: 0;
          }
        }
        &:not(:first-of-type) .user_subscr {
          border-top: 1px solid pd.color(border, forms);
        }
      }
      .user_avatar {
        width: pd.size(avatar, icons);
        height: pd.size(avatar, icons);
        flex: 0 0 calc(pd.size(avatar, icons) + 4px);
      }
      .user_user {
        // width: 20%;
        flex: 0 0 20%;
        > div > div:nth-child(2) {
          margin-left: pd.$xl;
        }
      }
      .user_messages {
        border: 1px solid pd.color(border, forms);
        border-radius: pd.$lg;
        border-top-left-radius: 0;
        padding: 0 4px !important;
        margin-top: 8px;
        width: max-content;
        @include pd.font($size: pd.size(smaller, font));
      }
      .invalid_subscriptions,
      .invalid_subscriptions input {
        color: pd.color(signal, forms);
        -webkit-text-fill-color: pd.color(signal, forms); // for Safari
      }
      .user_role {
        width: 100px;
        flex: 0 0 100px;
        div {
          width: min-content;
          cursor: pointer;
        }
        &.user_unverified {
          color: pd.color(signal, forms);
          @include pd.font($italic: true);
        }
      }
      .user_name,
      .user_role {
        .button {
          width: 100%;
        }
      }
      .user_subscr {
        // width: calc((100% - 40px - 100px - 10% - 10% - 90px) / 2);
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        &.user_project {
          flex: 0 0 25%;
          // overflow-x: hidden;
        }
      }
      .user_save {
        width: 90px;
        flex: 0 0 90px;
        display: flex;
        flex-direction: column;
        button {
          display: block;
          height: 24px;
          min-width: 80px;
          padding: 0 pd.$sm;
          &:not([disabled]) {
            opacity: 1;
          }
          opacity: 0;
        }
      }
      .subscription_item {
        display: flex;
        flex-direction: row;
      }
      .project_name,
      .item_remove,
      .dialogue_name {
        flex: 0 0 auto;
        margin: 0;
        width: 90%; // give space for bin
      }
      .item_remove {
        width: pd.size(tiny, icons);
        visibility: hidden;
        margin: 0 0 0 pd.$xl;
      }
      .user_moderator {
        width: pd.size(tiny, icons);
        margin: 0 pd.$xxl 0 auto;
        &:not(.is_moderator) {
          visibility: hidden;
        }
      }
      .add_menu {
        margin-top: pd.$md;
      }
    }
  }
}
