/* Logo */
@use '../../css/settings.scss' as pd;

.logo {
  position: relative;
  background-color: transparent;
  margin: 0 20px 0 24px;
  width: 150px;
  height: 32px;
  /* see https://www.exratione.com/2011/09/how-to-overflow-a-background-image-using-css3/ */
  &::before {
    content: ' ';
    position: absolute;
    background-image: var(
      --pd-logo-fn,
      url('../../../public/img/pd_logo_nl_bg_w.svg')
    );
    background-position: 0 12px;
    background-size: contain;
    background-repeat: no-repeat;
    width: 160px;
    height: 70px;
    // z-index: -1;
  }
}
