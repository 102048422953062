/* DialogueView */
@use '../../css/settings.scss' as pd;

.dialogue_container {
  position: relative;
  z-index: 50;
  flex-grow: 1;
  margin-top: max(1vw, pd.$xxl);
  @media screen and (max-width: 600px) {
    margin-top: pd.$md;
  }
  overflow: hidden;
  display: flex;
  // gap: pd.$sm;
  flex-wrap: nowrap;
  margin: pd.$xxl 0 0 pd.$lg;
  padding: 54px 0 0;
  .phases {
    flex-grow: 1;
    padding-bottom: pd.$xl;
    overflow: auto;
    padding-top: 18px;
  }
  .dialogue_title {
    @include pd.font($condensed: true, $size: max(3vw, 2rem), $bold: true);
    margin: 0 0 16px 32px;
    @media screen and (max-width: 600px) {
      margin: 0 0 5px 16px;
    }
  }
}
