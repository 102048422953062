/* ListSettingsForm */
@use '../../css/settings.scss' as pd;

.list_settings .sortable_list {
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  width: 100%;
}
.list_settings {
  .sortable:not(:last-child) {
    padding-right: 5px;
  }
}
.list_settings_list {
  .list_symbol {
    height: 100px;
    width: 70px;
    @include pd.bgicon($icon: longlist);
    margin: 5px auto;
    position: relative;
  }
  .list_remove {
    opacity: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: pd.color(bg, forms);
  }
  .list_symbol:hover .list_remove {
    opacity: 1;
  }
  .list_title {
    display: flex;
    &::after {
      content: '*';
      margin-left: pd.$md;
    }
  }
}
.admin_note {
  color: pd.color(text, forms);
  &::before {
    content: '*';
    margin-right: pd.$md;
  }
}
