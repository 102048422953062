/* ChatBlock */
@use '../../css/settings.scss' as pd;

.chat {
  padding-bottom: pd.$xxl;
  .messages {
    display: flex;
    flex-direction: column;
  }
  .sortable_wrapper {
    &.dragging {
      cursor: copy !important;
    }
    .sortable.byme {
      margin-left: auto;
    }
    .msg_width_auto {
      width: auto;
    }
    &:not(.dragging) {
      .sortable {
        // min-width: calc(pd.size(default, blocks) * 0.6);
        min-width: 50%;
      }
    }
    .msg_width_full {
      // width: calc(pd.size(default, blocks) - (2 * pd.size(xxl)));
      width: 100%;
    }
    .sortable:not(.byme) {
      margin-right: pd.size(xxl);
    }
    .msg_width_80 {
      // width: calc(pd.size(default, blocks) * 0.8);
      width: 80%;
    }
    .msg_width_60 {
      // width: calc(pd.size(default, blocks) * 0.6);
      width: 60%;
    }
    .msg_width_50 {
      // width: calc(pd.size(default, blocks) * 0.5);
      width: 50%;
    }
  }
  .msg {
    // width: 100%;
    &:not(.byme):before {
      position: absolute;
      height: pd.$xxl;
      width: pd.$xxl;
      left: pd.$mxxl;
      bottom: pd.$sm;
      background-image: url('../../../public/icons/leader.svg');
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: pd.size(xxl);
      content: ' ';
    }
    &.byme {
      margin-right: pd.size(xxl);
      align-self: flex-end;
      &.same_author.same_time .msg_menu:hover::before {
        background-color: pd.color(mybg, chat);
      }
    }
    &.edited {
      padding-bottom: pd.size(md);
    }
    &.same_author {
      margin-top: pd.size(tiny);
      .msg_author {
        display: none;
      }
    }
    &.same_author.same_time {
      .msg_author,
      .msg_time {
        display: none;
      }
      .msg_header {
        height: unset;
      }
      .msg_menu {
        position: absolute;
        right: pd.size(lg);
        top: pd.size(tiny);
        > * {
          z-index: 10;
        }
      }
      .dropmenu_trigger {
        width: pd.size(small, icons);
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .msg_replyto .msg_author {
      display: block;
    }
  }
}
.chat {
  .sortable_handle {
    margin-left: 7px;
    align-self: unset;
    margin-top: pd.$xl;
  }
  .sortable {
    // &.dragging,
    &:hover {
      .msg {
        border-top-left-radius: 0;
      }
    }
  }
  .same_author .sortable_handle {
    margin-top: pd.$tiny;
  }
  .byme .sortable_handle {
    background-color: pd.color(mybg, chat);
  }
}
.no_messages {
  padding: pd.$md;
  @include pd.font($italic: true);
  flex: 1 1 auto;
}
