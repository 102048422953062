/* SIZES */
$tiny: 3px;
$sm: 5px;
$md: 8px;
$lg: 12px;
$xl: 16px;
$xxl: 20px;
$xxxl: 32px;

$mtiny: 0- $tiny;
$msm: 0- $sm;
$mmd: 0- $md;
$mlg: 0- $lg;
$mxl: 0- $xl;
$mxxl: 0- $xxl;
$mxxxl: 0- $xxxl;

$font_size: 1rem;
$font_size_nav: min(1.1rem, max(1.4vw, 0.9rem));

$sizes: (
  tiny: $tiny,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  xxl: $xxl,
  xxxl: $xxxl,
  mtiny: $mtiny,
  msm: $msm,
  mmd: $mmd,
  mlg: $mlg,
  mxl: $mxl,
  mxxl: $mxxl,
  mxxxl: $mxxxl,
  cards: (
    width: 450px,
    wide: 700px,
    height: 660px,
  ),
  project_img: (
    width: 150px,
    height: 150px,
  ),
  dialogue_img: (
    width: 90px,
    height: 90px,
  ),
  blocks: (
    default: 400px,
    default_min: 300px,
    default_max: 600px,
    lists_1: 400px,
    lists_1_min: 300px,
    lists_1_max: 600px,
    lists_2: 600px,
    lists_2_min: 400px,
    lists_2_max: 800px,
    lists_3: 900px,
    lists_3_min: 500px,
    lists_3_max: 1200px,
    lists_4: 600px,
    lists_4_min: 400px,
    lists_4_max: 800px,
    lists_canvas: 900px,
    lists_canvas_min: 500px,
    lists_canvas_max: 1200px,
    library: 500px,
    library_max: 800px,
    document: 600px,
    document_max: 1200px,
    meeting: 400px,
    none: 70px,
  ),
  bubble: (
    unread: 24px,
    qmark: 24px,
  ),
  icons: (
    like: 18px,
    dot: 16px,
    tiny: 16px,
    small: 24px,
    normal: 32px,
    medium: 48px,
    large: 64px,
    avatar: 36px,
    avatar_large: 96px,
    avatar_medium: 64px,
    avatar_small: 32px,
    avatar_tiny: 24px,
  ),
  font: (
    xlarge: 2rem,
    larger: 1.5rem,
    large: 1.2rem,
    normal: $font_size,
    medium: 1rem,
    small: 0.9rem,
    smaller: 0.8rem,
    tiny: 0.6rem,
    nav: $font_size_nav,
    title: 2.5rem,
    subtitle: 1.5rem,
    subsubtitle: 1.2rem,
    landing: 1.8rem,
    link: 1.6rem,
    h1: 1.4rem,
    h2: 1.3rem,
    h3: 1.2rem,
    msg: 1rem,
    btn: 1.2rem,
    btn_sm: 0.9rem,
  ),
);

$accent: rgb(255, 150, 30);
$accent2: rgb(26, 152, 14);
$accent3: rgb(201, 210, 36);
$accent4: rgb(14, 148, 192);
$accentRed: rgb(255, 0, 0);
$accentGreen: rgb(0, 255, 0);
$accent_dark: darken($accent, 10%);
$accent_light: lighten($accent, 10%);
$accent_verylight: lighten($accent, 30%);

/* COLORS */
$colors: (
  // for project and dialogue pages
  bg: rgb(105, 105, 105),
  lighterbg: rgb(95, 95, 95),
  border: rgb(198, 198, 198),
  white: rgba(255, 255, 255),
  text: rgb(229, 229, 229),
  accent: $accent,
  accent2: $accent2,
  accent3: $accent3,
  accent4: $accent4,
  accentRed: $accentRed,
  accent_dark: $accent_dark,
  warning: $accent,
  silverhair: rgb(240, 240, 240),
  greyhair: rgb(190, 190, 190),
  // for navigation
  nav:
    (
      bg: inherit,
      text: inherit,
      accent: rgba(255, 255, 255, 0.2),
    ),
  // for notifications
  notify:
    (
      overlay: rgba(0, 0, 0, 0.7),
      bg1: rgb(255, 245, 197),
      bg2: darken(rgb(255, 245, 197), 10%),
      bg3: $accent_dark,
      text1: rgb(62, 62, 62),
      text2: rgb(62, 62, 62),
      text3: rgb(229, 229, 229),
    ),
  // for static pages
  home:
    (
      bg: $accent,
      text: rgb(62, 62, 62),
      accent: rgb(105, 68, 25),
    ),
  // for cards and blocks
  cards:
    (
      bg: rgb(255, 255, 255),
      border: rgb(198, 198, 198),
      text: rgb(62, 62, 62),
      lighttext: rgb(131, 131, 131),
      accent: $accent,
      divider: rgb(131, 131, 131),
      divider_2: rgb(190, 190, 190),
    ),
  // for forms and dropmenus
  forms:
    (
      bg: rgb(255, 255, 255),
      bg50: rgba(255, 255, 255, 0.5),
      text: rgb(62, 62, 62),
      accent: $accent,
      label: rgb(62, 62, 62),
      border: rgb(198, 198, 198),
      border_light: rgb(240, 240, 240),
      itembg: rgb(240, 240, 240),
      dropzone: rgb(105, 68, 25),
      signal: rgb(212, 40, 40),
    ),
  // for popmenus
  popmenu:
    (
      bg: rgb(250, 250, 250),
      border: rgb(198, 198, 198),
    ),
  wizard: (
    // bg: rgb(240, 240, 240),
    bg: $accent_light,
    border: $accent_light
  ),
  btn: (
    bg: rgb(62, 62, 62),
    text: rgb(255, 255, 255),
    border: rgb(131, 131, 131),
  ),
  btn-primary: (
    bg: $accent,
    text: rgb(255, 255, 255),
  ),
  btn-outline-primary: (
    border: $accent,
    bg: rgb(255, 255, 255),
    text: $accent,
  ),
  btn-secondary: (
    bg: rgb(62, 62, 62),
    text: rgb(255, 255, 255),
  ),
  btn-outline-secondary: (
    border: rgb(131, 131, 131),
    bg: rgb(255, 255, 255),
    text: rgb(0, 0, 0),
  ),
  // for phases
  phases:
    (
      bg: rgb(163, 163, 163),
      bg-d: darken(rgb(163, 163, 163), 10%),
      bg-l: lighten(rgb(163, 163, 163), 20%),
      text: rgb(255, 255, 255),
      border: rgb(131, 131, 131),
      accent: $accent,
      accent-d: darken($accent, 10%),
      unread: rgb(131, 131, 131),
      unread_bg: rgb(255, 255, 255),
      active_unread: $accent,
      active_unread_bg: rgb(255, 255, 255),
      fixed_unread: rgb(255, 255, 255),
      fixed_unread_bg: rgb(160, 160, 160),
    ),
  // for blocks
  block-controller:
    (
      bg: rgb(233, 235, 249),
      bg-d: darken(rgb(233, 235, 249), 10%),
      text: rgb(131, 131, 131),
      // text: rgb(255, 255, 255),
    ),
  blocks: (
    bg: rgb(255, 255, 255),
    tailbg: rgb(216, 216, 216),
    tailbg2: rgb(246, 246, 246),
    text: rgb(62, 62, 62),
    lighttext: rgb(131, 131, 131),
    accent: $accent,
    border: rgb(190, 190, 190),
    unread: rgb(255, 255, 255),
    unread_bg: rgb(160, 160, 160),
    resize_handle: rgb(100, 170, 255),
  ),
  absolute: (
    bg: rgb(255, 255, 255),
    border: rgb(163, 163, 163),
    menu: rgb(255, 255, 255),
  ),
  sortable: (
    indicator: rgb(29, 78, 216),
    indicator2: rgb(216, 216, 29),
    target_bg: rgba(220, 220, 220, 0.4),
  ),
  upload: (
    dropzone: rgba(0, 160, 255, 0.1),
  ),
  procon: (
    pro: rgb(182, 222, 197),
    con: rgb(255, 213, 163),
    tailpro: rgb(152, 212, 175),
    tailcon: rgb(255, 198, 128),
  ),
  poll: (
    gauge: $accent,
    gauge_bg: rgb(230, 230, 230),
    option_bg: rgb(255, 255, 255),
  ),
  // for tooltips
  tooltips:
    (
      bg: rgb(255, 245, 197),
      bg-d: darken(rgb(255, 245, 197), 10%),
      text: rgb(62, 62, 62),
      border: rgb(62, 62, 62),
      accent: $accent,
    ),
  // for chat
  chat:
    (
      bg: rgb(235, 235, 235),
      mybg: rgb(221, 225, 252),
      minibg: lighten(rgb(235, 235, 235), 5%),
      minimybg: lighten(rgb(221, 225, 252), 4%),
      isnewbg: rgb(255, 255, 255),
      isnewbdr: rgb(142, 142, 142),
      text: rgb(0, 0, 0),
      label: rgb(150, 150, 150),
      link: rgb(0, 0, 150),
      hoverlink: rgb(10, 80, 150),
    ),
  document: (
    bg: rgb(255, 255, 255),
    text: rgb(0, 0, 0),
    toolbar: rgb(235, 235, 235),
    border: rgb(190, 190, 190),
  ),
  list_canvas: (
    bg: rgb(240, 240, 240),
  ),
  colorpicker: (
    btn: rgb(220, 220, 220),
  ),
  meeting: (
    bg: rgb(62, 62, 62),
    name: rgb(255, 255, 255),
  ),
  sudoku: (
    challenge: rgb(0, 0, 0),
    attempt: rgb(40, 40, 40),
    hints: rgb(200, 200, 200),
  )
);

$box_shadow: 1px 1px 6px 0px
  rgba(
    $color: black,
    $alpha: 0.2,
  );

/* FONT */
:root {
  --extra-weight: 0;
}
$-font_weights: (
  'regular': 400,
  400: 400,
  'medium': 500,
  500: 500,
  'bold': 700,
  700: 700,
);

$arrow_font: Arial, Helvetica, sans-serif;
$arrow: '\2794  ';

/* VARIABLES */
// create the css variables for the values above
:root {
  // sizes
  @each $name, $val in $sizes {
    @if type-of($val) == 'map' {
      @each $size, $subval in $val {
        --pd-#{$size}-#{$name}: #{$subval};
      }
    } @else {
      --pd-#{$name}: #{$val};
    }
  }
  // colors
  @each $name, $val in $colors {
    @if type-of($val) == 'map' {
      @each $color, $subval in $val {
        --pd-color-#{$color}-#{$name}: #{$subval};
      }
    } @else {
      --pd-color-#{$name}: #{$val};
    }
  }
}

/* FUNCTIONS and MIXINS */
@function size($name, $scheme: null, $true-val: false) {
  @if ($true-val == true) {
    @if ($scheme != null) {
      @return map-get(map-get($sizes, $scheme), $name);
    } @else {
      @return map-get($sizes, $name);
    }
  } @else {
    @if ($scheme != null) {
      @if (map-get(map-get($sizes, $scheme), $name) != null) {
        @return var(--pd-#{$name}-#{$scheme});
      } @else {
        @return null;
      }
    } @else if (map-get($sizes, $name) != null) {
      @return var(--pd-#{$name});
    } @else {
      @return null;
    }
  }
}

@function color($name, $scheme: null, $true-val: false) {
  @if ($true-val == true) {
    @if ($scheme != null) {
      @return map-get(map-get($colors, $scheme), $name);
    } @else {
      @return map-get($colors, $name);
    }
  } @else {
    @if ($scheme != null) {
      @return var(--pd-color-#{$name}-#{$scheme});
    } @else {
      @return var(--pd-color-#{$name});
    }
  }
}

@mixin font(
  $family: false,
  $nav: false,
  $condensed: false,
  $size: null,
  $weight: null,
  $bold: false,
  $medium: false,
  $italic: false,
  $line: null
) {
  @if ($nav) {
    font-family: ('Helvetica Neu', 'Helvetica', 'Arial', sans-serif);
  } @else if($family or $condensed) {
    font-family: ('roboto#{if($condensed, '-condensed', '')}', sans-serif);
  }
  @if ($line) {
    line-height: $line;
  }
  @if ($bold or $medium or $weight) {
    $-w: #{if($bold, 'bold', #{if($medium, 'medium', $weight)})};
    font-weight: min(
      700,
      calc(var(--extra-weight) + #{map-get($map: $-font_weights, $key: $-w)})
    );
  }
  @if ($italic) {
    // font-style: #{if($italic, italic, normal)};
    font-style: italic;
  }
  @if ($size) {
    $fsz: size($size, font);
    font-size: #{if($fsz, $fsz, $size)};
  }
}

@mixin bgicon(
  $icon,
  $variant: '',
  $position: left,
  $size: unset,
  $repeat: no-repeat
) {
  $fn: if($variant != '', '#{$icon}-#{$variant}', $icon);
  background-image: url('../../public/icons/#{$fn}.svg') !important;
  background-repeat: $repeat;
  background-position: $position;
  background-size: $size;
}
/* END SETTINGS */
