/* Collapsable */
@use '../../css/settings.scss' as pd;

.collapsable_container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  // gap: pd.$xl;
  height: 100%;
}
.collapsable {
  display: flex;
  position: relative;
  &:not(.height) {
    flex-direction: row;
    .collapsable_controller {
      flex-direction: column;
      .collapsable_symbol,
      .collapsable_icon {
        margin: 0 auto;
      }
      .collapsable_icon {
        margin-top: pd.$xl;
        margin-bottom: pd.$lg;
      }
    }
    .collapsable_content {
      margin-left: pd.$xl;
      margin-right: pd.$xl;
      .collapsable_content {
        margin-left: unset;
        margin-right: unset;
      }
    }
  }
  &.height {
    flex-direction: column;
    .collapsable_controller {
      flex-direction: row;
      .collapsable_symbol,
      .collapsable_icon {
        // margin: auto 0;
        margin: 0;
      }
      .collapsable_icon {
        margin-right: pd.$sm;
      }
    }
    .collapsable_content {
      margin-bottom: pd.$xl;
      .collapsable_content {
        margin-bottom: unset;
      }
    }
  }
  .collapsable_controller {
    position: relative;
    display: flex;
    justify-content: flex-start;
    border-radius: pd.$md;
    cursor: pointer;
    &.hide {
      display: none;
    }
    &.disabled {
      cursor: default;
    }
  }
  .collapsable_symbol,
  .collapsable_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: pd.size(small, icons);
    aspect-ratio: 1;
    text-align: center;
    cursor: pointer;
    .pd_icon {
      height: 100%;
      aspect-ratio: 1;
    }
  }
  .collapsable_symbol {
    @include pd.font($size: 48px);
    height: pd.size(large, icons);
  }
  .collapsable_tooltip {
    pointer-events: none; // this avoids flickering on hover¡
    position: absolute;
    z-index: 2000;
    top: 40px;
    left: 65px;
    min-width: 200px;
    max-width: 400px;
    width: max-content;
    display: none;
    background-color: pd.color(bg, tooltips);
    color: pd.color(text, tooltips);
    border-radius: pd.$sm;
    box-shadow: pd.$box_shadow;
    overflow: hidden;
    .tooltip_box {
      padding: pd.$md pd.$md 0;
      .title {
        @include pd.font($bold: true, $size: 1.1rem);
        background-color: pd.color(bg-d, tooltips);
        margin: pd.$mmd pd.$mmd 0; //pd.$md;
        padding: pd.$md;
      }
      .text {
        padding: pd.$md 0;
      }
      .period {
        @include pd.font($italic: true, $size: 0.9rem);
        padding: pd.$md 0 0;
      }
    }
  }
  // .collapsable_tooltip_trigger:hover + div + .collapsable_tooltip:not(:hover),
  .collapsable_tooltip_trigger:hover ~ .collapsable_tooltip:not(:hover) {
    display: block;
  }
}
