/* Phase */
@use '../../css/settings.scss' as pd;

.phase.collapsable {
  &:not(.open) {
    margin-right: pd.size(xl);
  }
  > .collapsable_controller {
    width: 75px;
    background-color: pd.color(bg, phases);
    color: pd.color(text, phases);
    &:hover {
      background-color: pd.color(bg-d, phases);
    }
    .phase_name {
      @include pd.font($size: 32px);
      display: block;
      white-space: nowrap;
      padding-top: 24px;
      width: min-content;
      .phase_name_inner {
        transform: translate(-64px, -35%);
        .unread_count {
          display: inline-block;
          transform-origin: top left;
          transform: rotate(-90deg) translate(-18px, 10px);
          flex-direction: column;
          padding-top: 1px;
          background-color: pd.color(unread_bg, phases);
          color: pd.color(unread, phases);
        }
      }
      transform-origin: bottom left;
      transform: rotate(90deg);
    }
    &.active {
      background-color: pd.color(accent, phases);
      &:hover {
        background-color: pd.color(accent-d, phases);
      }
      .phase_name .phase_name_inner .unread_count {
        background-color: pd.color(active_unread_bg, phases);
        color: pd.color(active_unread, phases);
      }
    }
  }
  .blocks {
    border-radius: pd.$sm;
    .no_blocks {
      padding: pd.$sm;
    }
  }
  &.phase_fixed {
    .phase_name .phase_name_inner .unread_count {
      background-color: pd.color(fixed_unread_bg, phases);
      color: pd.color(fixed_unread, phases);
    }
  }
  .blocks {
    display: flex;
    flex-direction: row;
    > div {
      display: flex;
    }
    > .sortable_wrapper > .drop-indicator {
      background-color: pd.color(indicator2, sortable);
      &::before {
        border: 2px solid pd.color(indicator2, sortable);
      }
    }
  }
  .no_blocks {
    width: pd.size(none, blocks);
  }
}

.phase_fixed.collapsable {
  > .collapsable_controller {
    background-color: pd.color(bg, block-controller);
    color: pd.color(text, block-controller);
    &:hover {
      background-color: pd.color(bg-d, block-controller);
    }
  }
  > .collapsable_content {
    margin-left: 0;
  }
}
