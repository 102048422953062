@use '../../css/settings.scss' as pd;

.woo_container {
  margin-top: max(1vw, 20px);
  padding: 54px pd.$lg pd.$lg;
  width: 100%;
  .form-label {
    font-size: pd.size(small, font);
    @include pd.font($weight: bold, $size: normal);
    margin-top: pd.$md;
  }
  button.btn {
    width: 100%;
    max-width: unset;
    margin-top: pd.$lg;
    margin-bottom: pd.$lg;
  }
  .msg {
    padding: pd.$md pd.$lg pd.$md pd.$lg;
    margin: pd.$xl pd.$md 0 pd.$md;
    &.byme {
      border: 1px solid pd.color(bg, chat) !important;
      background-color: pd.color(bg, chat) !important;
    }
  }
  .form-control {
    margin-bottom: pd.$md;
    .woo_textarea {
      resize: vertical;
      overflow: auto;
    }
  }
  .woo_locale {
    position: absolute;
    right: 0;
    top: 20px;
  }
  .msg_file {
    margin: 0 pd.$mlg 0 pd.$mlg;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.msg {
  + .woo_score {
    margin-top: pd.$lg;
  }
}
.woo_score {
  &.woo_score_initem {
    background-color: pd.color(white);
    border-radius: pd.$md;
    padding: pd.$md;
    margin-top: pd.$md;
  }
  .woo_score_title {
  }
  .woo_score_item {
    display: flex;
    flex-direction: row;
    gap: pd.$md;
    .woo_score_label {
      width: 30%;
      text-align: right;
    }
    .woo_bar {
      width: 120px;
      position: relative;
      .woo_gauge {
        position: absolute;
        z-index: 10;
        height: 100%;
        background-color: pd.$accent;
      }
      .woo_stars {
        position: relative;
        z-index: 20;
        width: 100%;
        height: 100%;
        background-image: url('../../../public/icons/star_bg.svg');
        background-repeat: repeat-x;
        &.woo_stars_grey {
          background-image: url('../../../public/icons/star_bg_grey.svg');
        }
      }
    }
  }
}
