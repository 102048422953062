/* ResizableHandle */
@use '../../css/settings.scss' as pd;

.resizable {
  display: flex;
  flex-direction: row;
  position: relative;
  // margin-right: -6px;
  > div {
    z-index: 10;
  }
  .resizable_handle {
    width: 6px;
    cursor: col-resize;
    height: 100%;
    align-self: stretch;
    // margin: 20px 0 40px;
    position: absolute;
    // position: relative;
    top: 0;
    right: -6px;
    z-index: 5;
    &.dragging,
    &:hover {
      border-right: 4px solid pd.color(resize_handle, blocks);
    }
  }
}
// .resizable_handle {
//   width: 6px;
//   cursor: col-resize;
//   border-right: 3px double pd.color(resize_handle_bg, blocks);
//   border-top-right-radius: 6px;
//   border-bottom-right-radius: 6px;
//   height: 25%;
//   margin: auto 0;
//   position: absolute;
//   top: 0;
// }
