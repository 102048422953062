/* cards */
@use './settings.scss' as pd;

.cards_container {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
}
.card {
  flex: 1 0 auto;
  max-width: 100%;
  @media screen and (min-width: 800px) {
    max-width: 49%;
  }
  @media screen and (min-width: 1100px) {
    max-width: 32%;
  }
  margin-bottom: pd.$xxl;
  display: flex;
  flex-direction: column;
  padding: pd.$lg pd.$xxl;
  border-radius: pd.$sm;
  border: 1px solid pd.color(border, cards);
  background-color: pd.color(bg, cards);
  color: pd.color(text, cards);
  &.card_wide {
    flex: 1 0 auto;
    @media screen and (min-width: 800px) {
      max-width: 100%;
    }
    @media screen and (min-width: 1000px) {
      max-width: 49%;
    }
  }
  box-shadow: pd.$box_shadow;
  transition: background-color 0.4s;
  .card_header {
    display: flex;
    justify-content: space-between;
    height: 32px;
    .card_label {
      @include pd.font($size: 1.1rem);
      color: pd.color(accent, cards);
      margin-bottom: pd.$sm;
    }
  }
  .card_title {
    flex-grow: 1;
    @include pd.font(
      $condensed: true,
      $bold: true,
      $size: min(pd.size(title, font), max(3.5vw, pd.size(subtitle, font)))
    );
    line-height: 1.2;
    margin: 0 -2px pd.$xl;
  }
  .card_img {
    flex-grow: 0;
    aspect-ratio: 1 / 1;
    img {
      width: 100%;
      aspect-ratio: 1 / 1;
      object-fit: contain;
    }
  }
  .card_body {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    .card_body_top {
      position: relative;
      .card_descr a {
        text-decoration: underline;
      }
    }
    .card_descr {
      min-height: max-content;
      .text {
        white-space: pre-wrap;
      }
      &.bounded {
        overflow: hidden;
        text-overflow: ellipsis;
        line-clamp: 6;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
      }
    }
    a:hover .card_title,
    a:hover .card_subtitle div:not(.unread_count),
    a:hover .text {
      text-decoration: underline;
    }
    .card_subtitle {
      display: flex;
      position: relative;
      @include pd.font(
        $condensed: true,
        $bold: true,
        $size: pd.size(subtitle, font)
      );
    }
    .unread_count {
      margin: pd.$sm 0 0 pd.$lg;
      flex: 0 0 auto;
    }
    a:hover .unread_count {
      text-decoration: none;
    }
    .card_divider,
    .card_subdivider {
      height: 0px;
      width: 100%;
      background-color: pd.color(divider, cards);
      margin: pd.$xxl 0 pd.$md;
    }
    .card_subdivider {
      height: 0px;
      background-color: pd.color(divider_2, cards, true);
      margin: pd.$xxl 0 pd.$xxl;
    }
    a {
      text-decoration: none;
    }
    a:hover {
      text-decoration: none;
    }
  }
}
