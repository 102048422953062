/* LocaleSelector */
@use '../../css/settings.scss' as pd;

.localeselector {
  cursor: pointer;
}
.modal-dialog {
  .localeselector {
    margin-left: auto;
    margin-right: pd.$md;
    @include pd.font($size: pd.size(normal, font), $weight: regular);
  }
}
