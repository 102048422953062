/* SettingsForm */
@use '../../css/settings.scss' as pd;

.pd_modal .modal-dialog.settings_form {
  // width: calc(min(1160px, 80vw));
  // max-width: unset;
  color: pd.color(text, forms);
  .admin_section {
    display: flex;
    button {
      margin-left: pd.$md;
      margin-left: auto;
    }
  }
}
.pd_modal .phases,
.pd_modal .dialogues {
  display: flex;
  flex-direction: column;
  gap: pd.$sm;
}

.sortable_preview,
.pd_modal .phases,
.pd_modal .dialogues {
  .settings_item {
    display: flex;
    flex-direction: row;
    padding: 0.375rem;
    background-color: pd.color(itembg, forms);
    border: 1px solid #ced4da !important;
  }
  .phase_active {
    border-color: pd.color(accent, phases) !important;
  }
  .phase_number {
    @include pd.font($size: pd.size(xlarge, font));
    width: 32px;
    + * {
      width: unset;
      flex: 1 1 auto;
    }
  }
}
.sortable_preview,
.modal-dialog.settings_form {
  .collapsable:not(.block) {
    padding: 0.375rem 0.75rem;
  }
  // .collapsable_controller.label {
  //   margin-left: pd.size(mxxl);
  // }
  // .collapsable_icon {
  //   height: pd.size(tiny, icons);
  //   visibility: hidden;
  // }
  // .collapsable_controller.label:hover .collapsable_icon {
  //   visibility: visible;
  // }
  .collapsable_content .label {
    margin-top: pd.size(md);
  }
  .descr {
    flex: 1 1 auto;
  }
  .period {
    flex: 0 0 350px;
    margin-left: auto;
  }
  .title {
    @include pd.font($family: true, $bold: true, $size: pd.size(title, font));
  }
  .subtitle {
    @include pd.font(
      $family: true,
      $bold: true,
      $size: pd.size(subtitle, font)
    );
  }
  .subsubtitle {
    @include pd.font(
      $family: true,
      $bold: true,
      $size: pd.size(subsubtitle, font)
    );
  }
  .subheading {
    @include pd.font($size: pd.size(large, font));
    white-space: nowrap;
  }
}
.form-control {
  color: pd.color(text, forms);
}

.title {
  @include pd.font($condensed: true, $bold: true, $size: pd.size(title, font));
}
.subtitle {
  @include pd.font($bold: true, $size: pd.size(subtitle, font));
}
.text {
  @include pd.font($size: pd.size(normal, font));
}
.header {
  display: flex;
  align-items: baseline;
  color: pd.color(accent);
  .title {
    @include pd.font($family: true, $size: pd.size(subtitle, font));
  }
  .period {
    margin-left: auto;
    text-align: end;
  }
}
.settings_item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  color: pd.color(text, forms);
  width: 100%;
}
.settings_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  color: pd.color(text, forms);
  width: 100%;
}
.settings_col {
  // flex-grow: 1;
  flex: 1 1 auto;
  &.plch,
  &.img {
    flex-grow: 0;
    margin-right: pd.$lg;
    margin-top: pd.$md;
    align-self: flex-start;
    // adding 4 for dropzone border of 2
    width: calc(pd.size(width, dialogue_img) + 4);
    height: calc(pd.size(height, dialogue_img) + 4);
    .preview_image {
      max-width: pd.size(width, dialogue_img);
      max-height: pd.size(height, dialogue_img);
    }
    img {
      aspect-ratio: 1 / 1;
      width: pd.size(width, dialogue_img);
      object-fit: contain;
    }
  }
  &.img_r {
    margin-right: 0;
    margin-left: pd.$lg;
  }
}

.sortable.phase,
.sortable.dialogue {
  .sortable_handle {
    margin-left: -18px;
    margin-top: 8px;
    z-index: 3000;
    background-color: pd.color(itembg, forms);
    border: 1px solid pd.color(itembg, forms);
    border-radius: pd.$md;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
    width: 19px;
    height: 40px;
    padding: 0;
    &::after {
      @include pd.bgicon(
        'drag2x4',
        $variant: '',
        $position: center,
        $size: 24px,
        $repeat: no-repeat
      );
      background-clip: content-box;
      content: ' ';
      position: absolute;
      padding: 0;
      height: 40px;
      width: 19px;
    }
  }
}

.user_list {
  width: max-content;
}
.user_item {
  display: flex;
  gap: pd.$lg;
  width: 100%;
  .user_remove {
    width: pd.size(tiny, icons);
    visibility: hidden;
  }
  &:hover {
    .user_remove {
      visibility: visible;
    }
  }
  .user_moderator {
    margin-left: auto;
    margin-right: pd.$xl;
  }
}
.user_missing {
  color: pd.color(signal, forms) !important;
}

.guest_access,
.regcode {
  position: relative;
  display: flex;
  align-items: baseline;
  gap: pd.$sm;
  z-index: 10;
  padding: 0.375rem 0.75rem;
}
.reg_access,
.guest_access {
  display: flex;
  position: relative;
  align-items: baseline;
  z-index: 5;
  * {
    text-wrap: nowrap;
    white-space: nowrap;
  }
  input {
    flex: 1 1;
    max-width: 300px;
  }
  .reg_access_link {
    flex: 0 0;
  }
}
.reg_access {
  align-items: stretch;
  .sharing {
    align-self: center;
    > * {
      display: block;
      margin-left: auto;
    }
  }
}
.guest_access .regcode {
  padding-top: unset;
  padding-right: unset;
}
.phase_item.phase_active .active_phase .form-check-input:disabled {
  opacity: 1;
}
.dialogue_item,
.phase_item {
  .phase_number {
    margin-left: 12px;
    padding-right: 0;
  }
  .active_phase {
    position: absolute;
    margin-left: pd.$mxxl;
    margin-top: -11px;
    z-index: 100;
    .form-check-input {
      width: pd.$xxl;
      height: pd.$xxl;
      &:disabled {
        opacity: 0;
      }
      &:checked {
        background-color: pd.color(accent, phases);
        border-color: pd.color(accent, phases);
      }
    }
  }
  &:hover {
    .dialogue_remove,
    .phase_remove {
      visibility: visible;
    }
  }
  .copy_dates {
    @include pd.font($size: pd.size(small, font));
    cursor: pointer;
    margin-left: pd.$lg;
  }
}
.project_form .project_remove,
.dialogue_remove,
.phase_remove {
  width: pd.size(tiny, icons);
  visibility: hidden;
  margin: pd.$lg 0 0 auto;
}
.project_regcodeperiod {
  align-items: flex-start;
  &:hover .project_remove {
    visibility: visible;
  }
  .project_regcode {
    display: flex;
    .label.regcode {
      padding-left: 0.75rem;
      white-space: nowrap;
    }
  }
}
.project_period {
  justify-content: flex-end;
  &:hover .project_remove {
    visibility: visible;
  }
  .project_regcode {
    display: flex;
    .label.regcode {
      padding-left: 0.75rem;
      white-space: nowrap;
    }
  }
}
.appsettings_form .pd_form {
  .checkbox {
    margin-left: pd.$md;
  }
}
