/* ListBlock */
@use '../../css/settings.scss' as pd;

.list_block.block_container {
  .block_content {
    padding: 0;
    .block_description {
      padding: pd.$sm pd.$md 0 pd.$xl;
    }
  }
  &.lists_1 {
    width: pd.size(lists_1, blocks);
    min-width: pd.size(lists_1_min, blocks);
    max-width: pd.size(lists_1_max, blocks);
  }
  &.lists_2 {
    width: pd.size(lists_2, blocks);
    min-width: pd.size(lists_2_min, blocks);
    max-width: pd.size(lists_2_max, blocks);
    .list_container {
      width: 50%;
    }
  }
  &.lists_3 {
    width: pd.size(lists_3, blocks);
    min-width: pd.size(lists_3_min, blocks);
    max-width: pd.size(lists_3_max, blocks);
    .list_container {
      width: 33.333%;
    }
  }
  &.lists_4 {
    width: pd.size(lists_4, blocks);
    min-width: pd.size(lists_4_min, blocks);
    max-width: pd.size(lists_4_max, blocks);
    .list_container {
      width: 50%;
      height: 50%;
    }
  }
  &.lists_canvas {
    width: pd.size(lists_canvas, blocks);
    min-width: pd.size(lists_canvas_min, blocks);
    max-width: pd.size(lists_canvas_max, blocks);
  }
}

.lists {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  flex-wrap: wrap;
  overflow: hidden;
  .list_content {
    padding-top: pd.$md;
    .sortable_list {
      padding-bottom: pd.$xxl;
    }
  }
  &.lists_2,
  &.lists_3 {
    .list_container:not(:last-child) {
      border-right: 1px solid pd.color(border, blocks);
    }
  }
  .list_name {
    text-transform: uppercase;
  }
}

.list_head {
  padding-top: pd.$md;
  .list_title {
    padding: 0 0 0 pd.$md;
  }
}

// SWOT block
.lists.SWOT {
  .list_container:nth-child(1),
  .list_container:nth-child(3) {
    border-right: 1px solid pd.color(border, blocks);
    .list_name {
      margin-left: auto;
    }
    .list_icon {
      order: 2;
    }
  }
  .list_container:nth-child(1),
  .list_container:nth-child(2) {
    border-bottom: 1px solid pd.color(border, blocks);
    .list_head {
      padding-top: pd.$sm;
    }
  }
  .list_container:nth-child(3),
  .list_container:nth-child(4) {
    padding-top: pd.$sm;
  }
  .list_tail {
    .tail_container {
      background-color: pd.color(tailbg2, blocks);
    }
    .form-control {
      border: 2px solid pd.color(tailbg, blocks);
    }
    .msg_tail .msg_input_field {
      padding: pd.$tiny pd.$md pd.$md;
    }
  }
}

// PROCON
.lists.ProCon {
  .list_name {
    text-transform: uppercase;
    padding: pd.$tiny pd.$lg;
    width: fit-content;
    text-align: center;
    justify-content: center;
    max-width: 45%;
    background-color: pd.color(pro, procon);
    border-radius: pd.$sm;
  }
  .list_head {
    margin-bottom: pd.$md;
  }
  .list_name:nth-child(2) {
    margin-left: auto;
    background-color: pd.color(con, procon);
    .list_icon {
      order: 2;
    }
  }
  .list_content .sortable:not(.pinned) {
    width: 70%;
    flex: unset;
    .sortable_handle,
    &.draggingLeft.draggedOver,
    &.pro {
      .sortable_handle,
      .msg:hover .dropmenu::before,
      .msg:hover .dropmenu::before,
      .msg {
        background-color: pd.color(pro, procon);
      }
      .msg_mini:hover .dropmenu::before {
        background-color: inherit;
      }
    }
    &.draggingRight.draggedOver,
    &.con:not(.draggingLeft.draggedOver) {
      margin-left: auto;
      .sortable_handle,
      .msg:hover .dropmenu::before,
      .msg:hover .dropmenu::before,
      .msg {
        background-color: pd.color(con, procon);
      }
      .msg_mini:hover .dropmenu::before {
        background-color: inherit;
      }
    }
  }
  .tail_container {
    background-color: pd.color(tailpro, procon);
    padding-top: pd.$tiny;
  }
  .tail_procon.con .tail_container {
    background-color: pd.color(tailcon, procon);
  }
  .tail_tabs {
    display: flex;
    border-top-right-radius: pd.$lg;
    text-transform: uppercase;
    cursor: pointer;
    .tab_procon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      padding-left: pd.$xl;
      padding-top: pd.$tiny;
      border-top-right-radius: pd.$lg;
      background-color: pd.color(tailpro, procon);
      div:not(.pd_icon_img) {
        margin: 0 pd.$sm;
      }
      @include pd.font($size: pd.size(normal, font));
      &.tab_con {
        background-color: pd.color(tailcon, procon);
        .list_icon {
          order: 2;
        }
      }
    }
  }
  .tail_procon.pro {
    .tail_tabs {
      background-color: pd.color(tailcon, procon);
    }
    .tab_pro {
      @include pd.font($bold: true);
    }
  }
  .tail_procon.con {
    .tail_tabs {
      background-color: pd.color(tailpro, procon);
    }
    .tab_con {
      border-top-left-radius: pd.$lg;
      @include pd.font($bold: true);
    }
  }
}
