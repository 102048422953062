/* ProjectOverview */
@use '../../css/settings.scss' as pd;

.projects_container {
  margin-top: max(1vw, pd.$xxl);
  padding-top: 54px;
  width: 100%;
  @media screen and (max-width: 600px) {
    margin-top: pd.$md;
  }

  .my_projects {
    @include pd.font(
      $condensed: true,
      $size: max(3vw, pd.size(title, font)),
      $bold: true
    );
    margin: 0 0 16px 32px;
    @media screen and (max-width: 600px) {
      margin: 0 0 5px 16px;
    }
  }
  .loading_projects,
  .no_projects {
    @include pd.font($size: pd.size(large, font));
    > * {
      display: inline;
    }
  }
  .nav_item {
    text-decoration: underline;
    margin-left: unset;
  }

  .projects {
    margin: 0 32px 16px;
    @media screen and (max-width: 600px) {
      margin: 0 16px 16px;
    }
    .card {
      height: fit-content;
    }
    .dialogues {
      margin: 0 0 pd.$xl 0;
      .dialogue {
        display: flex;
        flex-direction: row;
        // min-height: 124px;
        .card_subtitle {
          @include pd.font($bold: true);
        }
        .card_descr {
          margin-left: pd.$xxl;
          margin-top: pd.$mmd;
          flex: 1 1 auto;
          .text {
            @include pd.font($weight: regular);
          }
        }
        &:hover .card_img .status {
          opacity: 1;
        }
      }
    }
    .card_img {
      position: relative;
      width: pd.size(width, dialogue_img);
      height: pd.size(height, dialogue_img);
      flex: 0 0 auto;
      .status {
        @include pd.font($size: pd.size(smaller, font), $weight: bold);
        color: pd.color(accent, cards);
        margin-bottom: unset;
        text-align: center;
        position: absolute;
        background-color: white;
        width: 70px;
        transform: translate(-24px, -84px) rotate(-45deg);
        opacity: 0;
      }
    }
    .card_body_top .card_img {
      margin-right: pd.$md;
      margin-left: auto;
      float: right;
      width: pd.size(width, project_img);
      height: pd.size(height, project_img);
    }
    .subscribed {
      padding-left: 20px;
      // margin-top: pd.$md;
      margin-bottom: pd.$xxl;
      color: pd.color(lighttext, cards);
      @include pd.font($size: pd.size(small, font));
    }
    .subscribe_link:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
