.autocomplete-no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.autocomplete-suggestions ul {
  // border: 1px solid #999;
  // border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  // width: calc(300px + 1rem);
}

.autocomplete-suggestions li {
  // padding: 0.5rem;
}

.autocomplete-suggestion-active,
.autocomplete-suggestions li:hover {
  // background-color: #008f68;
  // color: #fae042;
  cursor: pointer;
  font-weight: 700;
}

.autocomplete-suggestions li:not(:last-of-type) {
  // border-bottom: 1px solid #999;
}
