/* MeetingGallery */
@use '../../css/settings.scss' as pd;

.meeting {
  z-index: 100;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: pd.$xl;
  border-left: pd.$sm solid transparent;
  margin-left: pd.$msm;
  border-image: linear-gradient(to right, rgba(0, 0, 0, 0), pd.color(bg)) 1 100%;
  padding-left: calc(2 * pd.$sm);
  .collapsable_controller {
    width: 50px;
    padding-top: 42px;
    .meeting_label {
      transform-origin: bottom left;
      transform: rotate(90deg);
      .meeting_label_inner {
        transform: translate(-60px, -4%);
        .unread_count {
          display: inline-block;
          transform-origin: top left;
          transform: rotate(-90deg) translate(-18px, 10px);
          flex-direction: column;
          padding-top: 1px;
          background-color: pd.color(unread_bg, phases);
          color: pd.color(unread, phases);
        }
      }
    }
  }
  &.collapsable .collapsable_content {
    flex-direction: column;
    margin-left: 4px;
    .meeting_icon {
      left: 4px;
    }
    .meeting_label {
      margin: 15px auto 0;
      padding-top: 0;
      @include pd.font($size: 18px);
      user-select: none;
    }
    &.show {
      display: flex;
    }
    &.collapsing .meeting_icon {
      visibility: hidden;
    }
  }
}
.meeting_label {
  @include pd.font($size: 32px);
  display: block;
  white-space: nowrap;
  padding-top: 36px;
  width: min-content;
}
.meeting_icon {
  position: absolute;
  top: 20px;
  left: 14px;
  cursor: pointer;
}
.meeting_recordings {
  padding: pd.$sm pd.$md;
  margin-bottom: pd.$lg;
  // max-height: 25%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  > div:first-child {
    @include pd.font($bold: true);
  }
  > ul {
    overflow-y: auto;
    margin-bottom: 0;
  }
}
.meeting_entrance {
  position: relative;
  margin: 20px auto;
  width: 100px;
  height: 100px;
  text-align: center;
  padding: 4px;
  border: 2px dashed pd.color(border, blocks);
  border-radius: 50%;
  cursor: pointer;
  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: pd.color(accent);
    color: white;
    width: 100%;
    height: 100%;
    cursor: pointer;
    @include pd.font($size: pd.size(large, font), $bold: true);
  }
}
.meeting_preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 194px;
  .meeting_preview_label {
    text-align: center;
  }
  .meeting_preview_users {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    > div {
      display: flex;
      flex-direction: column;
      margin: pd.$md;
      .user_avatar {
        margin: 0 auto;
      }
      .msg_author {
        margin: 0 auto;
        max-width: 80px;
        overflow-wrap: anywhere;
        white-space: unset;
      }
    }
  }
}
.meeting_head {
  flex: 0 0 auto;
}
.meeting_content {
  width: 194px;
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  // height: 100%;
  overflow: hidden;
}
.meeting_peers {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: pd.color(border, absolute);
    border-radius: 6px;
  }
  video {
    max-width: 190px;
    max-height: 113px;
    border-radius: 6px;
  }
}
.meeting_me,
.meeting_peer {
  border-radius: 6px;
  width: 190px;
  height: 113px;
  position: relative;
  background-color: pd.color(bg, meeting);
  margin-bottom: 4px;
  &:nth-child(odd) {
    margin-right: 4px;
  }
}
.meeting_controls {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 2px;
  display: flex;
  justify-content: flex-end;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.4);
  > div {
    position: relative;
  }
}
.meeting_name {
  position: absolute;
  bottom: 0;
  padding: 0 4px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.4);
  color: pd.color(name, meeting);
  max-width: 140px;
  width: max-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include pd.font($size: pd.size(small, font));
}
.meeting_avatar {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 10%;
  left: 0;
  width: 100%;
  height: 100%;
}
.meeting_menu {
  position: relative;
  padding-top: 8px;
  top: 0;
  right: 0;
  padding-right: 4px;
  display: flex;
  justify-content: flex-end;
  > div {
    margin-left: 10px;
    position: relative;
  }
  .meeting_exit {
    margin-top: -12px;
  }
}
