/* ColorPickerButton */
@use '../../css/settings.scss' as pd;

.block:not(.locked) .colorpicker_btn {
  cursor: pointer;
}
.msg:hover .colorpicker_btn.noColor {
  // border-color: transparent transparent pd.color(btn, colorpicker) transparent !important;
  border-color: transparent transparent transparent pd.color(btn, colorpicker) !important;
}
