/* Like */
@use '../../css/settings.scss' as pd;

.like {
  margin-left: auto;
  display: flex;
  justify-content: right;
  bottom: 0px;
  right: 0px;
  @include pd.font($size: pd.size(smaller, font));
  .like_icon,
  .dot {
    aspect-ratio: 1;
    width: pd.size(like, icons);
    background-repeat: no-repeat;
    background-position: center;
    // background-size: pd.size(like, icons);
    background-size: contain;
    // border: 1px solid green;
  }
  .like_count {
    visibility: hidden;
  }
  &:not(.liked) .like_icon {
    background-image: url('../../../public/icons/thumb-light.svg');
    filter: brightness(0.7);
    * {
      filter: unset;
    }
  }
  &.liked {
    .like_icon {
      background-image: url('../../../public/icons/thumb-dark.svg');
    }
    .like_count {
      margin-right: pd.size(sm);
      visibility: visible;
    }
  }
  &.i_liked {
    .like_icon {
      background-image: url('../../../public/icons/thumb-filled.svg');
    }
  }
  &.dot {
    .like_icon {
      z-index: 10;
    }
    &:not(.liked) .like_icon {
      background-image: url('../../../public/icons/dot-light.svg');
    }
    &.liked .like_icon {
      background-image: url('../../../public/icons/dot.svg');
    }
    &.i_liked {
      .like_icon {
        background-image: url('../../../public/icons/dot-filled.svg');
      }
    }
    .dot {
      z-index: 5;
      width: pd.size(dot, icons);
      margin-right: -4px;
      background-image: url('../../../public/icons/dot-accent-filled.svg');
      // background-size: pd.size(dot, icons);
    }
  }
}
.block:not(.locked) .like {
  cursor: pointer;
  &:hover .like_icon {
    background-image: url('../../../public/icons/thumb-dark.svg');
  }
  &.dot:hover .like_icon {
    background-image: url('../../../public/icons/dot-dark.svg');
  }
  &.i_liked {
    &:hover .like_icon {
      background-image: url('../../../public/icons/thumb-dark-filled.svg');
    }
    &.dot:hover .like_icon {
      background-image: url('../../../public/icons/dot-dark-filled.svg');
    }
  }
}
