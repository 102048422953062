/* ListCanvas */
@use '../../css/settings.scss' as pd;

.list_canvas {
  flex: 1 1 auto;
  background-color: pd.color(bg, list_canvas);
  .sortable_wrapper {
    width: unset;
  }
  .msg {
    margin: 0;
    .popmenu_items {
      top: -28px;
      right: -8px;
    }
  }
}
