/* Navigation */
@use '../../css/settings.scss' as pd;

.nav_item {
  cursor: pointer;
  display: inline;
  position: relative;
  margin-right: 16px;
  &.right {
    margin-left: auto;
    + .right {
      margin-left: unset;
    }
  }
  &:hover {
    text-decoration: underline;
  }
}
.navigation_container {
  position: fixed;
  top: 0;
  width: 100%;
  // make sure navigation is on top of siblings
  z-index: 100;
}
.notifications_overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: pd.color(overlay, notify);
  z-index: 150;
}
.notifications {
  position: absolute;
  width: 100%;
  top: 80px;
  z-index: 999;
  display: flex;
  align-items: baseline;
  color: pd.color(text1, notify);
  padding: pd.$md pd.$md pd.$sm pd.$xl;
  a {
    text-decoration: underline;
  }
  &.async_ready {
    // border-bottom: 2px solid pd.$accent;
  }
  &.async_fail1 {
    background-color: pd.color(bg1, notify);
    color: pd.color(text1, notify);
  }
  &.async_fail2 {
    background-color: pd.color(bg2, notify);
    color: pd.color(text2, notify);
  }
  &.async_fail3 {
    background-color: pd.color(bg3, notify);
    color: pd.color(text3, notify);
  }
}
.navigation {
  display: flex;
  align-items: flex-start;
  min-height: 66px;
  padding: 4px 8px 0 0;
  @include pd.font($nav: true, $size: pd.size(nav, font));
  background-color: pd.color(bg);
  color: pd.color(text);
  & + * {
    z-index: 50;
  }
  > div {
    margin-top: 8px;
  }
  .nav_home {
    margin-top: 0;
  }
  .nav_title {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    flex: 1 0 auto;
    align-items: center;
    .project_name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .dialogue_name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include pd.font($bold: true);
      // margin-right: 40px;
    }
  }
  .main_item {
    font-weight: bold;
  }
  .nav_item_cta {
    min-width: 6rem;
    text-align: center;
    background-color: pd.color(accent);
    color: pd.color(white);
    border: 2px solid pd.color(accent);
    border-radius: 100vw;
    margin-top: 6px;
    padding: 0 0.6vw;
    &:hover {
      background-color: pd.color(white);
      color: pd.color(text, home);
      text-decoration: none;
    }
  }
  .nav_cta {
    position: absolute;
    top: 55vh;
    left: 65%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: fit-content;
    .nav_item {
      position: relative;
      width: 100%;
      text-align: center;
      @include pd.font($size: 110%, $weight: medium);
      background-color: white;
      color: pd.color(text, home);
      border: 2px solid pd.color(accent);
      border-radius: 9999px;
      padding: 0 0.6vw;
      margin-left: 2.8vw;
      text-decoration: none;
      &::before {
        background-image: url('../../../public/icons/arrow-dark.svg');
        background-size: 1.8vw;
        background-repeat: no-repeat;
        background-position: center;
        content: ' ';
        width: 1.8vw;
        height: 1.8vw;
        display: block;
        position: absolute;
        top: 0.1vw;
        left: -2.4vw;
      }
    }
  }
  & + .nav_info {
    position: absolute;
    z-index: 150;
    width: 100%;
  }
  .extraweight {
    @include pd.font(
      $nav: true,
      $size: calc(1.2 * pd.size(nav, font)),
      $bold: true
    );
    text-align: center;
    height: 1.4em;
    aspect-ratio: 1;
    cursor: pointer;
    border: 1px solid;
    border-radius: pd.$sm;
    text-anchor: end;
    &.selected {
      border-radius: 4px;
      background-color: pd.color(accent, nav, true);
      // color: pd.color(text);
    }
  }
  .nav_help.with_add_tool {
    margin-right: 40px;
  }
  .nav_user {
    display: flex;
    flex-direction: column;
    overflow-y: visible;
    height: 32px;
    margin-top: 0px;
    margin-right: 0;
  }
  .user_avatar {
    margin: 0 10px 0 10px;
  }
  .dropmenu a {
    display: block;
  }
  .user_name {
    position: absolute;
    right: 10px;
    top: 40px;
    text-align: end;
    font-size: 80%;
    width: max-content;
    &.center {
      text-align: center;
      right: 0px;
      width: 55px;
    }
    &.guest {
      @include pd.font($italic: true);
    }
  }
}

.layout {
  &.with_background .navigation {
    background-color: unset;
    color: pd.color(text, home);
  }
  &.white .navigation {
    background-color: pd.color(white);
    color: pd.color(text, home);
  }
}
