/* PeriodPicker */
@use '../../css/settings.scss' as pd;

.timepicker_button,
.datepicker_button {
  cursor: pointer;
}
.react-datepicker-popper {
  z-index: 4000 !important;
}
.date.as-form-control,
.time.as-form-control {
  padding: 0.15rem 0.375rem;
  width: 130px;
  input[type='time'] {
    border: 0;
    color: pd.color(text, forms);
  }
}
.react-datepicker-wrapper {
  input {
    padding: 0;
    border: 0;
    width: 100%;
    color: pd.color(text, forms);
  }
  .datepicker_clear {
    background-color: unset !important;
    right: -3px;
    &::after {
      background-color: pd.color(border, forms);
      color: pd.color(text, forms);
    }
  }
}
