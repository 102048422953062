/* WizardForm */
@use '../../css/settings.scss' as pd;

.wizard_form {
  .collapsable.height .collapsable_controller {
    background-color: pd.color(bg, wizard);
    border: 2px solid pd.color(border, wizard);
    margin: 0 -12px pd.$lg;
    padding: 10px 12px;
    @include pd.font($bold: true, $size: pd.size(large, font));
    .collapsable_icon {
      margin-left: auto;
      order: 2;
    }
    .collapsable_symbol {
      @include pd.font($size: 26px);
      height: pd.size(small, icons);
    }
  }
  .subrow {
    display: flex;
    align-items: flex-end;
    gap: pd.$sm;
    margin-top: pd.$sm;
  }
  .label {
    margin-bottom: pd.$sm;
  }
  .settings_col.img {
    margin-top: 0;
  }
}
