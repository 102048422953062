/* DialogHeader */
@use '../../../css/settings.scss' as pd;

.modal-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  .dialog_header {
    display: flex;
    width: 100%;
    align-items: center;
    &.center {
      justify-content: center;
    }
    .search_item,
    .items_per_page,
    .checkbox {
      height: 28px;
      flex: 0 0 auto;
      margin-right: pd.$xxl;
      @include pd.font($size: pd.size(normal, font), $weight: regular);
    }
    .search_item {
      width: unset;
      margin-left: auto;
      + .search_close {
        margin-left: -42px;
      }
    }
    .show_ids {
      padding-top: 3px;
    }
    .items_per_page {
      flex: 0 0 70px;
    }
    .items_per_page_label {
      height: 28px;
      margin-left: auto;
      margin-right: pd.$md;
      flex: 0 0 auto;
      @include pd.font($size: pd.size(normal, font), $weight: regular);
    }
  }
}
