/* ToolHint */
@use '../../css/settings.scss' as pd;

.toolhint {
  position: absolute;
  pointer-events: none;
  z-index: 99;
  @include pd.font($size: pd.size(smaller, font), $weight: regular);
  color: pd.color(text, tooltips);
  background-color: pd.color(bg-d, tooltips);
  padding: pd.$sm pd.$md;
  border-radius: pd.$sm;
  box-shadow: pd.$box_shadow;
  text-align: center;
  // white-space: nowrap;
  text-decoration: none !important;
  width: auto;
  &.right {
    right: unset;
    left: 0;
  }
}
