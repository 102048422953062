/* DocumentBlock */
@use '../../css/settings.scss' as pd;

.document_block.block_container {
  width: pd.size(document, blocks);
  max-width: pd.size(document_max, blocks);
  .ql-tooltip {
    left: 0px !important;
  }
  .ql-snow {
    border: 1px solid pd.color(border, document);
    font-size: pd.size(small, font);
  }
  .ql-toolbar {
    z-index: 10;
    border-radius: pd.$sm pd.$sm 0 0;
    background-color: pd.color(toolbar, document);
    // &.ql-snow .ql-formats {
    //   margin-right: 0;
    // }
  }
  .document_container {
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    margin-top: pd.$sm;
  }
  .editor_container.ql-container {
    margin-top: -10px; // this is to make cursor flags in the first line visible
    padding-top: 10px;
    margin-bottom: 10px;
    z-index: 0; // this is to make the toolbar, heading options and the editor container all visible
    border-radius: 0 0 pd.$sm pd.$sm;
    overflow: auto;
    background-color: pd.color(bg, document);
  }
  .ql-editor {
    background-color: pd.color(bg, document);
  }
  .icon_save {
    margin-top: -1px;
    margin-right: pd.$sm;
  }
  .block_content.disabled {
    opacity: 1;
    .ql-toolbar {
      display: none;
    }
    .editor_container.ql-container {
      margin-top: 0;
      padding-top: 0;
      border-radius: pd.$sm;
      border: 1px solid pd.color(border, document);
    }
  }
}
