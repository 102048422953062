/* Block */
@use '../../css/settings.scss' as pd;

.block {
  // margin-right: pd.size(xl);
  display: flex;
}

.block.collapsable {
  > .collapsable_controller {
    width: 75px;
    background-color: pd.color(bg, block-controller);
    color: pd.color(text, block-controller);
    &:hover {
      background-color: pd.color(bg-d, block-controller);
    }
    .collapsable_tooltip {
      max-width: 300px;
    }
  }
}

.blocks
  > .sortable:hover
  > .sortable_wrapper
  > .sortable_content
  > .collapsable_controller,
.blocks
  .collapsable_content:not(.show)
  > .sortable:hover
  > .sortable_wrapper
  > .sortable_handle,
.blocks
  > .sortable.dragging
  > .sortable_wrapper
  > .sortable_content
  > .collapsable_controller,
.blocks > .sortable.dragging > .sortable_wrapper > .sortable_handle {
  background-color: pd.color(bg-d, block-controller);
}
.blocks > .sortable > .sortable_wrapper > .sortable_handle {
  background-color: pd.color(bg, block-controller);
  // background-color: pd.color(bg, blocks);
  &:hover {
    background-color: pd.color(bg-d, block-controller);
  }
}

.block_container {
  height: 100%;
  width: pd.size(default, blocks);
  min-width: pd.size(default_min, blocks);
  max-width: pd.size(default_max, blocks);
  background-color: pd.color(bg, blocks);
  color: pd.color(text, blocks);
  border-radius: pd.$md;
  padding: pd.$md 0 0;
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;
}
.for_pdf {
  border: 2px solid red;
  overflow-y: visible;
  height: max-content;
}
.block_head {
  padding: 0 pd.$md;
  border-bottom: 1px solid pd.color(silverhair);
  .block_bar {
    display: flex;
    justify-content: flex-start;
    .unread_count {
      margin-top: 0;
    }
  }
  .block_phase {
    display: flex;
    flex-grow: 1;
    color: pd.color(accent, blocks);
    margin-right: pd.$md;
    margin-left: pd.$md;
    white-space: nowrap;
    cursor: default;
    user-select: none;
  }
  .lock {
    margin-right: pd.$sm;
  }
}
.block_title {
  margin-left: pd.$md;
  @include pd.font($bold: true, $size: pd.size(subtitle, font));
}
.block_description {
  padding: pd.$sm pd.$md 0;
}
.block_content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  overflow-anchor: none;
  padding: 0 pd.$md;
}
.list_tail,
.block_tail {
  position: relative;
}

.tail_container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  // padding: pd.$lg pd.$md pd.$lg;
  padding: 0 pd.$sm pd.$sm;
  background-color: pd.color(tailbg, blocks);
  &.dropzone_image {
    padding-top: pd.$sm;
  }
  .preview_image {
    max-width: 100%;
    background-size: contain;
  }
  .dropmenu_trigger {
    height: 100%;
    // margin-right: pd.$md;
  }
  .dropzone_container {
    padding-bottom: pd.$sm;
  }
  .dropzone_trigger,
  .dropzone_bin {
    position: absolute;
    bottom: pd.$md;
    left: 0;
    float: unset;
    margin-top: -20px;
    z-index: 10;
  }
  .dropzone_bin {
    left: 32px;
  }
}

.msg_tail {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.msg_input {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin-right: pd.$sm;
  .form-control {
    @include pd.font($size: pd.size(msg, font));
    // border: 2px solid pd.color(tailbg, blocks);
    border: 2px solid transparent;
  }
  .msg_input_field {
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding-right: 24px;
  }
  .msg_replyto {
    @include pd.font($size: 80%);
    position: relative;
    .close_btn {
      position: absolute;
      top: pd.$sm;
      right: pd.$md;
      width: pd.size(tiny, icons);
      aspect-ratio: 1 / 1;
      cursor: pointer;
    }
  }
}
.msg_input_plus,
.msg_input_send {
  flex: 0 0 auto;
  background-repeat: no-repeat;
  // background-position: left -2px bottom 6px;
  background-position: 0 0;
  background-size: 24px;
  width: 24px;
  height: 28px;
  // aspect-ratio: 1 / 1;
  cursor: pointer;
}
.msg_input_plus {
  height: 28px;
  background-image: url('../../../public/icons/plus.svg');
}
.msg_input_send {
  background-image: url('../../../public/icons/send.svg');
  margin-left: -2px;
}
.msg_input_option:not(:last-child) {
  // margin-bottom: pd.$md;
}
